import React, { useRef } from 'react';
import useOutsideAlert from './useOutsideAlert';

interface ContextMenuProps {
    x: number,
    y: number,
    closeConextMenu: () => void,
    children: React.ReactNode,
    className: string,
};

const defaultProps = {
    className: '',
};

const ContextMenu = ({ x, y, closeConextMenu, children, className }: ContextMenuProps) => {

    const contextMenuRef = useRef<HTMLDivElement>(null);
    useOutsideAlert(contextMenuRef, closeConextMenu);
    return (
        <div
            className={`${className}`}
            ref={contextMenuRef}
            style={{top: `${y}px`, left: `${x}px`, position: 'absolute'}}
        >
            {children}
        </div>
    )
};

ContextMenu.defaultProps = defaultProps;

export default ContextMenu;