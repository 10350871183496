import React, { useEffect, useState } from'react';

type TextProps = {
    dataName: string,
    title: string,
    value: string,
    visible: boolean,
    readonly?: boolean,
    hintText?: string,
    required?: boolean,
    rows?: number,
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const TextArea= ({ dataName, title, value, visible, readonly, hintText, required, rows, onchange }: TextProps) => {

    const [updatedValue, setValue] = useState("");

    // This function is called when the input changes
    const textAreaHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const enteredValue = event.target.value;
        setValue(enteredValue);
        if (onchange) {
            onchange(event);
        }
    };

    // Set default value
    useEffect(() => {
        setValue(value);
    }, [value])

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <textarea readOnly={readonly} className="Value form-control input-lg" name={dataName}
                    value={readonly ? value : updatedValue} rows={rows} onChange={textAreaHandler} required={requiredValue} />
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

TextArea.defaultProps = defaultProps;

export default TextArea