import React, { useEffect, useState } from'react';
import { Form } from 'react-bootstrap';

type option = {
    value: string,
    label?: string
}

type SelectProps = {
    dataName: string,
    title: string,
    options: option[],
    value: string,
    visible: boolean,
    readonly?: boolean,
    hintText?: string,
    required?: boolean,
    allowSelectOption?: boolean,
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const SelectField = ({ dataName, title, options, value, visible, readonly, hintText, required, allowSelectOption, onchange }: SelectProps) => {

    const [updatedValue, setValue] = useState("");

    // This function is called when the selected value changes
    const selectHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setValue(selectedValue);
        if (onchange) {
            onchange(event);
        }
    };

    // Set default value
    useEffect(() => {
        setValue(value);
    }, [value])

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName + title}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>;
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <Form.Select disabled={readonly} className="form-select" name={dataName} id={dataName + title}
                    value={value !== "" ? value : updatedValue} onChange={selectHandler} required={requiredValue}>
                        <option disabled={!allowSelectOption} value="">Select...</option>
                        {options.map((option, i) => {
                            return <option key={i} value={option.value}>{option.label ? option.label : option.value}</option>
                        })}
                </Form.Select>
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

SelectField.defaultProps = defaultProps;

export default SelectField