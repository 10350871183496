import React, { useState, useRef } from 'react';
import { ListGroup, Row, Col } from 'react-bootstrap';
import ButtonType from './ButtonType';
import IFileUpload from '../types/FileUpload';

type FileUploadProps = {
    dataName: string,
    tableDataName?: string,
    filenameDataName?: string,
    title: string,
    filesIn: File[],
    readonlyValue?: boolean,
    hintText?: string,
    required?: boolean,
    multiple?: boolean,
    fileadd?: (e: any) => void,
    fileremove?: (e: any) => void
}

const fileTypes = [".txt", ".doc", ".docx", ".pdf", ".jpg", ".png", ".gif", ".jpeg", ".tiff", ".msg", ".eml", ".heic", ".xlsx"];
const fileSizeLimit = 10e6; // 10 MB

const FileUploadField = ({ dataName, tableDataName, filenameDataName, title, hintText, filesIn, readonlyValue, required, multiple, fileadd, fileremove }: FileUploadProps) => {
    const [files, setFiles] = useState<File[]>(filesIn);
    const inputFile = useRef<HTMLInputElement>(null);

    // This function is called when a file is selected
    const fileAddHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFiles: File[] = [];
            const arr = Array.from(event.target.files);
            arr.forEach((file: File) => {
                // check file size
                if (file.size > fileSizeLimit) {
                    alert("File is too big.")
                }

                // check file extension
                const fileName = file.name;
                var ext =  "." + fileName.split('.').pop();
                if (ext && fileTypes.includes(ext.toLowerCase())) {
                    newFiles.push(file);
                }
                else {
                    alert("Unsupported file type.");
                }
            })

            setFiles((existing: File[]) => [...existing, ...newFiles]);

            if (fileadd) {
                const fileUpload: IFileUpload = {
                    files: newFiles,
                    dataName: dataName,
                    tableDataName: tableDataName,
                    filenameDataName: filenameDataName
                }

                fileadd(fileUpload);
            }
        }
    };

    const fileRemoveHandler = (file: File) => {
        const newFiles = files;
        const index = newFiles.indexOf(file, 0);
        if (index > -1) {
            newFiles.splice(index, 1);        
            setFiles([...newFiles]);

            if (fileremove) {
                fileremove(file);
            }
        }
    };

    const addFilesButtonClick = (event: any) => {
        if (inputFile.current) {
            inputFile.current.click();
        }
    }

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} { required ? <label className="required">*</label> : "" }</label></b> <br /></div>
    }
    
    let fileList;
    if (files.length > 0) {
        fileList = 
            <ListGroup>
                {files.map((file, i) => {
                    return (
                        <ListGroup.Item key={i}>
                            <Row>
                                <Col>
                                    {file.name}
                                </Col>
                                <Col>
                                    <ButtonType className="ButtonRight" dataName="DeleteFile" color="red" size="sm" eventHandler={() => {fileRemoveHandler(file)}} buttonText="X" />
                                </Col>
                            </Row>
                        </ListGroup.Item>
                )})}
            </ListGroup>
    }

    return (
        <div className="form-group ComponentDivs" >
            {titleLabel}
            <p className="HintText">{hintText}</p>
            <input type="file" accept={fileTypes.join()} name={dataName} hidden={true} onChange={fileAddHandler}
                multiple={multiple} ref={inputFile}  />
            <ButtonType dataName="AddFile" readOnly={readonlyValue} type="button" buttonText="Select Files" eventHandler={addFilesButtonClick} />
            <p />
            {fileList}
        </div>
    );
}

export default FileUploadField