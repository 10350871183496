import { useEffect, useState } from 'react';
import { withCookies, Cookies } from 'react-cookie';
import { DataSource } from '../../types/Data/DataSource';
import SelectField from '../SelectField';

type Props = {
    dataSourceList: DataSource[];
    cookies: Cookies;
    cookiesName: string;
    selectionHandler?: (selection: string) => void;
};

const DataSourceDropdown = ({ dataSourceList, cookies, cookiesName, selectionHandler }: Props) => {

    const [selectedDataSource, setSelectedDataSource] = useState<string>("");

    // Set default value
    useEffect(() => {
        const dataSource = cookies.get(cookiesName);
        if(dataSourceList.find(x => x.externalId === dataSource) === undefined) {
            cookies.remove(cookiesName);
            setSelectedDataSource("");
            return;
        }
        if (dataSource) {
            setSelectedDataSource(dataSource);

            if (selectionHandler) {
                selectionHandler(dataSource);
            }
        }
        // excluding all to prevent running except on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleProjectChange = (event: any) => {
        setSelectedDataSource(event.target.value);
        if (selectionHandler) {
            selectionHandler(event.target.value);
        }
        cookies.set(cookiesName, event.target.value, { path: '/' });
      };

    return ( 
        <SelectField 
            dataName="dataSourceSelect" 
            title="Data Source"
            options={dataSourceList.map((ds) => ({label: ds.name, value: ds.externalId}))}
            value={selectedDataSource}
            onchange={handleProjectChange}
        />
    );
}

export default withCookies(DataSourceDropdown);