import { useEffect, useState } from 'react';
import Select from 'react-select';

type option = {
    value: string,
    label?: string
}

type SearchProps = {
    dataName: string,
    title: string,
    options: option[],
    value: string,
    visible: boolean,
    maxDisplayOptions?: number,
    findValueIncludes?: boolean, // Used to find value from options that INCLUDES the value instead of EQUALS the value (mainly for Work Code field)
    readonly?: boolean,
    hintText?: string,
    required?: boolean,
    allowSelectOption?: boolean,
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true,
    maxDisplayOptions: 50,
    findValueIncludes: false
}

const SearchField = ({ dataName, title, options, value, visible, maxDisplayOptions, findValueIncludes, readonly, hintText, required, onchange }: SearchProps) => {

    const [updatedValue, setUpdatedValue] = useState(value);
    const [inputValue, setInputValue] = useState("");

    // This function is called when the selected value changes
    const handleValueChange = (selectedOption: option | null) => {
        const selectedValue = selectedOption ? selectedOption.value : "";
        setUpdatedValue(selectedValue);
        if (onchange) {
            onchange(selectedValue);
        }
    };

    // This function is called when the search input changes
    const handleInputChange = (newInputValue: string) => {
        setInputValue(newInputValue);
    }

    // Filters the options based on input
    const filterOptions = () => {
        return options.filter((i) => 
            i.value.toLowerCase().includes(inputValue.toLowerCase())
        ).slice(0, maxDisplayOptions);
    }

    // Custom styling used in the Select component
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
            border: '1px solid #d2d8dd',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: 'black',
        }), 
        placeholder: (provided: any) => ({
            ...provided,
            color: 'black',
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            fontSize: '100px',
            color: '#3e4349',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            padding: '1px 10px',
            fontSize: '16px',
            backgroundColor: state.isFocused ? '#1967d2' : 'white',
            color: state.isFocused ? 'white' : 'black',
        }),
      };

    // Set default value
    useEffect(() => {
        setUpdatedValue(value);
    }, [value])

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName + title}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>;
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <Select
                    name={dataName}
                    options={filterOptions()}
                    isSearchable
                    isDisabled={readonly}
                    onChange={handleValueChange}
                    onInputChange={handleInputChange}
                    value={options.find(option => findValueIncludes ? option.value.includes(updatedValue) : option.value === updatedValue)}
                    styles={customStyles}
                />
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

SearchField.defaultProps = defaultProps;

export default SearchField