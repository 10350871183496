import { Component } from "react";
import { Link } from "react-router-dom";
import ITask from '../types/Task/Task';
import ITaskEvent from '../types/Task/TaskEvent';
import { ButtonGroup, ToggleButton, Table, Alert } from 'react-bootstrap';
import { MsalContext } from "@azure/msal-react";
import warningImage from '../images/warning.png'
import TaskService from '../services/TaskService'
import { appInsights } from "../services/appInsights";
import Loading from "../components/Loading";
import { EventStatus } from "../enums/EventStatus";
import { StageLabel } from '../enums/Stage';

type Props = {
  preloadedTasks?: ITask[];
  updateTasks?: (e: any) => void
};

export type TTaskList = ITask[]

type State = {
  tasks?: ITask[];
  events?: ITaskEvent[];
  message: string;
  radioValue: string;
}

export default class TasksPage extends Component<Props, State> {
  static contextType = MsalContext;

  private timerID: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      tasks: this.props.preloadedTasks,
      message: "",
      radioValue: "All"
    }
  };

  async componentDidMount() {
    appInsights.trackPageView({name: "Task page"});

    this.getTasks();
    this.getUserEvents();    
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getTasks() {
    await TaskService.getTasks(this.context.accounts[0].username)
      .then((response) => {
        if (this.state.tasks !== response.data) {
          this.setState({
            tasks: response.data
          });
          
          if (this.props.updateTasks) {
            this.props.updateTasks(response.data)
          }
        }
      })
      .catch((e) => {
        this.setState({
          tasks: []
        });
      });
  }

  async getUserEvents() {
    await TaskService.getUserEvents(this.context.accounts[0].username)
      .then((response) => {
        if (this.state.events !== response.data) {
          this.setState({
            events: response.data
          });
        }

        clearInterval(this.timerID);

        // Check if there are any pending or in-progress events
        if (response.data.filter(x => x.status !== EventStatus.Fail).length > 0) {
          this.timerID = window.setInterval(() => this.getUserEvents(), 5000);
        } // Check if there are any failed events
        else if (response.data.filter(x => x.status === EventStatus.Fail).length > 0) {
          this.getTasks();
        }
      })
      .catch((e) => {
        this.setState({
          events: []
        });
      });
  }

  processDateCompare(date: string) {

    var dueDate = new Date(date);

    if(dueDate.getTime() < Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  setChecked(value: string) {
    this.setState({
      radioValue: value,
    });
  }

  removeEvent(taskID: number) {
    var events = this.state.events;

    // Remove event from state
    if (events) {
      this.setState({
        events: events.filter(x => x.taskID !== taskID)
      });
    }
    
    // Call API to update dimiss flag
    TaskService.dismissUserEvents(taskID.toString(), this.context.accounts[0].username);
  }

  render() {
    const {
      tasks,
      events,
      radioValue
    } = this.state;

    if (!tasks) {
      return (
        <Loading showLoading={true} />
      )
    }

    var displayedTasks = tasks
      .filter(x => x.assignedTo.toLowerCase() === radioValue.toLowerCase() 
        || radioValue === "All")

    const items = displayedTasks.length > 0 ? displayedTasks.map((task) =>
      <tr key={task.id}>
          <td>{this.processDateCompare(task.dueDate) ? <img src={warningImage} width="20" height="20" alt=""/>  : ""}</td>
          <td className={this.processDateCompare(task.dueDate) ? "dueDateRed" : ""}>
            {new Date(task.dueDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'})}
          </td>
          <td><Link to={{pathname: `/taskdetails/${task.id}`} }>{task.ncrID}</Link></td>
          <td>{task.projectNumber}</td>
          <td>{task.projectName}</td>
          <td>{task.description}</td>
          <td>{task.location}</td>
          <td>{task.ncrType}</td>
          <td>{StageLabel[task.stage]}</td>
      </tr>
      ) : <tr>
            <td colSpan={100}>
              <b>No Tasks Found</b>
            </td>
          </tr>;

    const radios = [
      { name: 'All', value: 'All' },
      { name: 'Mine', value: this.context.accounts[0].username },
      { name: 'Claimable', value: '' }
    ];

    return (
      <div className='w-75'>
        {events?.filter(x => x.status === EventStatus.Fail).map((event) => 
          <div>
          <Alert key={event.taskID} variant="danger" onClose={() => this.removeEvent(event.taskID)} dismissible>
            An error occurred while submitting <Link to={{pathname: `/taskdetails/${event.taskID}`} }>{event.ncrID}</Link>. Please submit the NCR again or contact IT Support.
          </Alert>
          </div>
        )}
        <h1 className="header mt-5 text-center">My Tasks</h1>
        <ButtonGroup className="mb-3">
          {radios.map((radio, idx) => (
            <ToggleButton
              className={radioValue === radio.value ? "toggled-button-active" : "toggled-button"}
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="outline-primary"
              name="radio"
              value={radio.value ?? ""}
              checked={radioValue === radio.value}
              onChange={(e) => this.setChecked(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>

        <Table>
            <thead>
                <tr>
                    <th style={{width: 10}}></th>
                    <th style={{width: 125}}>Due Date</th>
                    <th style={{width: 125}}>Report ID</th>
                    <th style={{width: 125}}>Project Number</th>
                    <th style={{width: 125}}>Project Name</th>
                    <th style={{width: 250}}>Description</th>
                    <th style={{width: 200}}>Location</th>
                    <th style={{width: 100}}>Type</th>
                    <th style={{width: 200}}>Stage</th>
                </tr>
            </thead>
            <tbody>
                {items}
            </tbody>
        </Table>
      </div>
    );
  }
}