import http from "../http-common";
import DataSourceActivityDetail from "../types/DataSource/DataSourceActivityDetail";
import DataSourceHistoryDetail from "../types/DataSource/DataSourceHistoryDetail";
import DataSourceRulesDetail from "../types/DataSource/DataSourceRulesDetail";

class DataSourceService {
  getDataSourceActivity(environmentID: string, externalID: string) {
    return http.get<DataSourceActivityDetail>(`/getDataSourceActivity?environmentID=${environmentID}&externalID=${externalID}`);
  }

  getDataSourceHistory(environmentID: string, externalID: string) {
    return http.get<DataSourceHistoryDetail>(`/getDataSourceHistory?environmentID=${environmentID}&externalID=${externalID}`);
  }

  getDataSourceRules(environmentID: string, externalID: string) {
    return http.get<DataSourceRulesDetail>(`/getDataSourceRules?environmentID=${environmentID}&externalID=${externalID}`);
  }

  getAllDataSourceRules(externalID: string = '') {
    return http.get<DataSourceRulesDetail[]>(`/getAllDataSourceRules?externalID=${externalID}`);
  }

  setDataSourceActivity(environmentID: string, externalID: string, updatedActivity: DataSourceActivityDetail) {
    return http.post(`/setDataSourceActivity?environmentID=${environmentID}&externalID=${externalID}`, updatedActivity, { headers: { 'Content-Type': 'application/json' }});
  }

  addDataSourceHistory(environmentID: string, externalID: string, historyToAdd: DataSourceHistoryDetail) {
    return http.post(`/addDataSourceHistory?environmentID=${environmentID}&externalID=${externalID}`, historyToAdd, { headers: { 'Content-Type': 'application/json' }});
  }

  setDataSourceRules(environmentID: string, externalID: string, ruleDetails: DataSourceRulesDetail) {
    return http.post(`/updateDataSourceRules?environmentID=${environmentID}&externalID=${externalID}`, ruleDetails, { headers: { 'Content-Type': 'application/json' }});
  }

  createDataSourceBackup(environmentID: string, externalID: string, employee: string) {
    return http.post(`/createDataSourceBackup?environmentID=${environmentID}&externalID=${externalID}&employee=${employee}`);
  }
}

const dataSourceServiceInstance = new DataSourceService();

export default dataSourceServiceInstance;