// Photo Data Names
export const ACTION_ITEM_PHOTO_FILE_NAME = "ActionItemPhotoTitle";
export const ACTION_ITEM_PHOTO_TABLE = "ActionItemPhotoTable";
export const ACTION_ITEM_PHOTO_URL = "ActionItemPhotoURL";
export const ADDITIONAL_PHOTO_FILE_NAME = "AdditionalPhotoTitle";
export const ADDITIONAL_PHOTO_TABLE = "AdditionalPhotoTable";
export const ADDITIONAL_PHOTO_URL = "AdditionalPhotoURL";

// Photo Titles
export const PHOTO_TITLE = "Photo/Document Upload";

// Engineering Review Data Names
export const ENGINEER_COMMENTS = "EngineerComments";
export const ENGINEER_EXTERNAL_FIRM = "ExtEngineerFirm";
export const ENGINEER_OWNER = "Owner";
export const ENGINEER_PERFORMED_BY = "EngineerPerformedBy";

// Engineering Review Titles
export const ENGINEER_COMMENTS_TITLE = "Comments";
export const ENGINEER_EXTERNAL_FIRM_TITLE = "External Engineering Firm";
export const ENGINEER_PERFORMED_BY_TITLE = "Engineering Performed By";

// Engineering Review Values
export const ENGINEER_PERFORMED_BY_BLATTNER = "Blattner";
export const ENGINEER_PERFORMED_BY_EXTERNAL = "External Engineering Firm";
export const ENGINEER_HEADER = "Engineering";

// Approval Data Names
export const APPROVAL_REJECTION_ASSIGNEE = "AssigneeRejection";

// Approval Titles
export const APPROVAL_ASSIGN_TO_TITLE = "Assign To";
export const APPROVAL_REJECTION_COMMENTS_TITLE = "Rejection Comments";
export const APPROVAL_REJECTION_REJECT_TO_TITLE = "Reject To";

// Approval Values
export const APPROVAL_APPROVE = "Approve";
export const APPROVAL_REJECT = "Reject";
export const APPROVAL_REJECT_ASSIGNEE = "Assignee";

// PmSm Approval Data Names
export const PM_SM_APPROVAL = "PMSMApproval";
export const PM_SM_APPROVAL_DATE = "PMSMApproval_Date";
export const PM_SM_APPROVAL_NAME = "PMSMApproval_Name";
export const PM_SM_REJECTION_COMMENTS = "PMSM_CommentsRejection";
export const PM_SM_REJECTION_REJECT_TO = "PMSMRejectTo";

// PmSm Approval Titles
export const PM_SM_APPROVAL_TITLE = "PM/SM Approval";

// Quality Approval Data Names
export const QUALITY_APPROVAL = "QualityApproval";
export const QUALITY_REJECTION_COMMENTS = "Quality_CommentsRejection";
export const QUALITY_REJECTION_REJECT_TO = "QualityRejectTo";

// Quality Approval Titles
export const QUALITY_APPROVAL_TITLE = "Quality Approval";

// Acknowledgment Values
export const ACKNOWLEDGMENT_VALUE = "Yes";

// DC Acknowledgment Data Names
export const DC_ACKNOWLEDGMENT = "DCAcknowledgment";

// DC Acknowledgment Titles
export const DC_ACKNOWLEDGMENT_HEADER = "DC Acknowledgment";

// DC Acknowledgment Values
export const DC_ACKNOWLEDGMENT_VALUE = "I acknowledge that I am aware of the nonconformance and have taken action to discuss it with the project team";

// Quality Expediting Data Names
export const ACTUAL_IMPLEMENTATION_DATE = "ActualImplDate";
export const ACUTAL_IMPLEMENTATION_CHANGES = "ActualImplementChanges";
export const ASSIGN_TO = "AssignTo";
export const ASSIGN_TO_IMPLEMENTATION = "AssignToImplement";
export const ASSIGN_TO_CHOICE = "AssignToChoice";
export const ASSIGN_TO_CHOICE_IMPLEMENTATION = "AssignToChoiceImplement";
export const CLOSE_NCR_STEP_1 = "AnalysisNeeded";
export const CLOSE_NCR_STEP_2 = "ImplementationNeeded";
export const CLOSURE_STATUS = "ClosureStatus";
export const IMPLEMENT_CHANGES = "ImplementChanges";
export const NO_ACTION_COMMENTS = "NoActionComments";
export const REASON_NO_ACTION = "ReasonNoAction";
export const TARGET_IMPLEMENTATION_DATE = "TargetImplDate";

// Quality Expediting Titles
export const ACTUAL_IMPLEMENTATION_DATE_TITLE = "Actual Implementation Date";
export const ACTUAL_IMPLEMENTATION_CHANGES_TITLE = "Actual Improvement Changes Implemented";
export const ASSIGN_TO_TITLE = "Assign To";
export const ASSIGN_TO_CHOICE_TITLE = "Assign Analysis To";
export const ASSIGN_TO_CHOICE_IMPLEMENTATION_TITLE = "Assign Implementation To";
export const CORPORATE_ANALYSIS = "Corporate Analysis";
export const CLOSE_NCR_STEP_1_TITLE = "Analysis Needed";
export const CLOSE_NCR_STEP_2_TITLE = "Implementation Needed";
export const CLOSURE_STATUS_TITLE= "NCR Closure Status";
export const IMPLEMENT_CHANGES_TITLE = "Continuous Improvement Changes to be Implemented";
export const NO_ACTION_COMMENTS_TITLE = "Comments";
export const REASON_NO_ACTION_TITLE = "Reason for No Action";
export const TARGET_IMPLEMENTATION_DATE_TITLE = "Target Implementation Date";

// Quality Expediting Values
export const ASSIGN_ANOTHER_PERSON = "Another Person";
export const ASSIGN_MYSELF = "Myself";
export const WITH_ACTION_ITEMS = "Yes";
export const WITHOUT_ACTION_ITEMS = "No";

// Costs Data Names
export const ADDITIONAL_BLATTNER_COST = "AdditionalBlattnerCost";
export const ADDITIONAL_COST_EXPLANATION = "AdditionalCostExplanation";
export const ADDITIONAL_NON_BLATTNER_COST = "AdditionalNonBlattnerCost";
export const BLATTNER_TOTAL_COST = "BlattnerTotalCost";
export const BLATTNER_TOTAL_COST_INPUT = "BlattnerTotalCostInput";
export const COMBINED_TOTAL_COST = "CombinedTotalCost";
export const COMBINED_TOTAL_COST_INPUT = "CombinedTotalCostInput";
export const COMPLETE_BY = "CompleteBy";
export const INVESTIGATION_BLATTNER_COST = "NcBlattnerCost";
export const INVESTIGATION_COST_EXPLANATION = "NcCostExplanation";
export const INVESTIGATION_NON_BLATTNER_COST = "NcNonBlattnerCost";
export const NON_BLATTNER_TOTAL_COST = "NonBlattnerTotalCost";
export const NON_BLATTNER_TOTAL_COST_INPUT = "NonBlattnerTotalCostInput";
export const OTHER_BLATTNER_COST = "OtherBlattnerCost";
export const OTHER_COST_EXPLANATION = "OtherCostExplanation";
export const OTHER_NON_BLATTNER_COST = "OtherNonBlattnerCost";
export const REINSTALLATION_BLATTNER_COST = "ReinstallationBlattnerCost";
export const REINSTALLATION_COST_EXPLANATION = "ReinstallationCostExplanation";
export const REINSTALLATION_NON_BLATTNER_COST = "ReinstallationNonBlattnerCost";
export const REMOVAL_BLATTNER_COST = "RemovalBlattnerCost";
export const REMOVAL_COST_EXPLANATION = "RemovalCostExplanation";
export const REMOVAL_NON_BLATTNER_COST = "RemovalNonBlattnerCost";
export const WORK_BLATTNER_COST = "WorkBlattnerCost";
export const WORK_COST_EXPLANATION = "WorkCostExplanation";
export const WORK_NON_BLATTNER_COST = "WorkNonBlattnerCost";

// Costs Refinement Data Names
export const ADDITIONAL_BLATTNER_COST_REFINEMENT = "AdditionalBlattnerCost_Refining";
export const ADDITIONAL_COST_EXPLANATION_REFINEMENT = "AdditionalCostExplanation_Refining";
export const ADDITIONAL_NON_BLATTNER_COST_REFINEMENT = "AdditionalNonBlattnerCost_Refining";
export const BLATTNER_TOTAL_COST_REFINEMENT = "BlattnerTotalCost_Refining";
export const BLATTNER_TOTAL_COST_INPUT_REFINEMENT = "BlattnerTotalCostInput_Refining";
export const COMBINED_TOTAL_COST_REFINEMENT = "CombinedTotalCost_Refining";
export const COMBINED_TOTAL_COST_INPUT_REFINEMENT = "CombinedTotalCostInput_Refining";
export const INVESTIGATION_BLATTNER_COST_REFINEMENT = "NcBlattnerCost_Refining";
export const INVESTIGATION_COST_EXPLANATION_REFINEMENT = "NcCostExplanation_Refining";
export const INVESTIGATION_NON_BLATTNER_COST_REFINEMENT = "NcNonBlattnerCost_Refining";
export const NON_BLATTNER_TOTAL_COST_REFINEMENT = "NonBlattnerTotalCost_Refining";
export const NON_BLATTNER_TOTAL_COST_INPUT_REFINEMENT = "NonBlattnerTotalCostInput_Refining";
export const OTHER_BLATTNER_COST_REFINEMENT = "OtherBlattnerCost_Refining";
export const OTHER_COST_EXPLANATION_REFINEMENT = "OtherCostExplanation_Refining";
export const OTHER_NON_BLATTNER_COST_REFINEMENT = "OtherNonBlattnerCost_Refining";
export const REINSTALLATION_BLATTNER_COST_REFINEMENT = "ReinstallationBlattnerCost_Refining";
export const REINSTALLATION_COST_EXPLANATION_REFINEMENT = "ReinstallationCostExplanation_Refining";
export const REINSTALLATION_NON_BLATTNER_COST_REFINEMENT = "ReinstallationNonBlattnerCost_Refining";
export const REMOVAL_BLATTNER_COST_REFINEMENT = "RemovalBlattnerCost_Refining";
export const REMOVAL_COST_EXPLANATION_REFINEMENT = "RemovalCostExplanation_Refining";
export const REMOVAL_NON_BLATTNER_COST_REFINEMENT = "RemovalNonBlattnerCost_Refining";
export const WORK_BLATTNER_COST_REFINEMENT = "WorkBlattnerCost_Refining";
export const WORK_COST_EXPLANATION_REFINEMENT = "WorkCostExplanation_Refining";
export const WORK_NON_BLATTNER_COST_REFINEMENT = "WorkNonBlattnerCost_Refining";

// Costs Titles/HelpText
export const ADDITIONAL_COST_HELP_TEXT = "Include meeting costs related to nonconformance";
export const ADDITIONAL_COST_TITLE = "Additional Meeting Cost";
export const BLATTNER_COST_TITLE = "Blattner Cost";
export const BLATTNER_COST_TITLE_REFINEMENT = "Blattner Cost Refinement";
export const BLATTNER_TOTAL_COST_TITLE = "Blattner Total Cost";
export const BLATTNER_TOTAL_COST_TITLE_REFINEMENT = "Blattner Total Cost Refinement";
export const COMBINED_TOTAL_COST_TITLE = "Combined Total Cost";
export const COMBINED_TOTAL_COST_TITLE_REFINEMENT = "Combined Total Cost Refinement";
export const EXPLANATION_COST_TITLE = "Explanation of Cost";
export const EXPLANATION_COST_TITLE_REFINEMENT = "Explanation of Cost Refinement";
export const INVESTIGATION_COST_HELP_TEXT = "Includes all team members involved";
export const INVESTIGATION_COST_TITLE = "Nonconformance Investigation Cost";
export const NON_BLATTNER_COST_TITLE = "Non-Blattner Cost";
export const NON_BLATTNER_COST_TITLE_REFINEMENT = "Non-Blattner Cost Refinement";
export const NON_BLATTNER_TOTAL_COST_TITLE = "Non-Blattner Total Cost";
export const NON_BLATTNER_TOTAL_COST_TITLE_REFINEMENT = "Non-Blattner Total Cost Refinement";
export const OTHER_COST_HELP_TEXT = "Include any additional costs not defined above";
export const OTHER_COST_TITLE = "Other/Additional Cost";
export const REINSTALLATIONL_COST_HELP_TEXT = "Include costs for labor, equipment, materials, engineering, etc.";
export const REINSTALLATION_COST_TITLE = "Reinstallation Cost";
export const REMOVAL_COST_HELP_TEXT = "Include costs for labor, equipment, materials, engineering, etc.";
export const REMOVAL_COST_TITLE = "Removal/Demolition Cost";
export const TOTAL_COST_TITLE = "Total Cost(s)";
export const WORK_COST_HELP_TEXT = "Include cost related to work stoppage";
export const WORK_COST_TITLE = "Work Stoppage Cost";

export const Stages = [
      "Unknown",
      "Started",
      "Submitted",
      "Assigned",
      "Engineering Review",
      "Supply Chain Review",
      "SM/PM Approval",
      "DC Acknowledgment",
      "Quality Approval",
      "Quality Refinement",
      "Quality Expediting",
      "Corporate Analysis",
      "Implementation",
      "Complete"
    ];
