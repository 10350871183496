import { useState } from 'react';
import Select from 'react-select';

import SelectAll from './Icons/SelectAll';

export interface multiSelectOption {
    value: string,
    label: string,
};

type MultiSelectProps = {
    onChange: (selectedOptions: multiSelectOption[]) => void,
    dataName: string,
    title: string,
    value: string,
    visible: boolean,
    readonly: boolean,
    required: boolean,
    options: multiSelectOption[],
    defaultOptions: multiSelectOption[],
    specialOptionsTitle: string,
    specialOptions: multiSelectOption[],
    error: boolean,
    errorMessage: string,
} & typeof defaultProps;

const defaultProps = {
    onChange: (data: multiSelectOption[]) => {},
    title: '',
    value: '',
    visible: true,
    readonly: false,
    required: false,
    defaultOptions: [] as multiSelectOption[],
    specialOptionsTitle: '',
    specialOptions: [] as multiSelectOption[],
    error: false,
    errorMessage: '',
};

const MultiSelectField = ({
    onChange,
    dataName,
    value,
    title,
    visible,
    readonly,
    required,
    options,
    specialOptionsTitle,
    specialOptions,
    error,
    errorMessage,
}: MultiSelectProps) => {

    const [values, setVaules] = useState<multiSelectOption[]>(value !== '' ? value.split(', ').map(x => ({ label: x, value: x})) : []);
    const [myKey, setMyKey] = useState<string>(`multiSelect_${Date.now()}`);

    const dangerRed = '#dc3545';
    const blattnerBlue = '#1967d2';
    const lightGray = '#FAFAFA';

    const handleAddClosureStatus = (selectedOptions: multiSelectOption[]) => {
        if (onChange) {
            onChange(selectedOptions);
        }
        setVaules(selectedOptions)
        value = selectedOptions.map(x => x.value).join(', ')
    }

    const handleSelectSpecial = () => {
        setVaules(specialOptions);
        handleAddClosureStatus(specialOptions);
        setMyKey(`multiSelect_${Date.now()}`);
    }

    const renderSpecialButton = () => {
        if (specialOptions.length > 0 && !readonly) {
            return (
                <label
                    onClick={() => handleSelectSpecial()}
                    title={specialOptionsTitle}
                >
                    &nbsp;<SelectAll />
                </label>
            );
        }
        return (<></>);
    }

    const renderRequired = () => {
        if (required) {
            return (
                <b>
                    <label className='required'>
                        &nbsp;*
                    </label>
                </b>
            );
        }
        return (<></>);
    }

    return (
        visible ?
        <div
            className={`${error ? 'bg-danger rounded border border-3 border-danger' : ''}`}
            title={`${error ? errorMessage : ''}`}
        >
            <div className={'pb-2'}>
                <b><label className={`${error ? 'text-white' : ''}`} htmlFor={dataName + title}>{title}</label></b>
                {renderRequired()}
                {renderSpecialButton()}
            </div>
            <Select
                styles={{
                    multiValue: ((style) => {
                        return {
                            ...style,
                            backgroundColor: lightGray,
                        };
                    }),
                    option: ((style, isSelected) => {
                        return {
                            ...style,
                            backgroundColor: isSelected ? 'white' : blattnerBlue,
                            ':hover': {
                                backgroundColor: error ? dangerRed : blattnerBlue,
                                color: 'white'
                            },
                        }
                    })
                }}
                id={dataName + title}
                name={dataName}
                key={myKey}
                value={values}
                options={options}
                isMulti
                isClearable
                isSearchable
                isDisabled={readonly}
                closeMenuOnSelect={false}
                onChange={(e: any) => handleAddClosureStatus(e)}
            />
        </div>
        : <></> // If not visible, return empty element instead of null
    );
};

MultiSelectField.defaultProps = defaultProps;

export default MultiSelectField;
