import FormApproverDetail from "../types/FormApprover/FormApproverDetail";
import http from "../http-common";
import FormApproverGroupDetail from "../types/FormApprover/FormApproverGroupDetail";

class FormApproverService {
    getFormApprovers(projectID: string) {
        return http.get<FormApproverDetail[]>(`/getFormApprovers?projectID=${projectID}`);
    }

    getFormApproversGroup(projectID: string) {
        return http.get<FormApproverGroupDetail[]>(`/getFormApproversGroup?projectID=${projectID}`);
    }
}

const formApproverInstance = new FormApproverService();

export default formApproverInstance;