import { useEffect, useState } from 'react';
import Cell from './DataCell';
import ContextMenu from './ContextMenu';
import { RowInsertType } from '../../enums/RowInsertType';
import { EditableDataSource, DataSourceCell } from '../../types/Data/DataSource';
import EyeSlash from '../Icons/EyeSlash';

const getDataHeaders = ({ headers }: EditableDataSource, data: DataSourceCell[][]) => (
    data[0].map((d) => d.columnIndex !== undefined ? headers[d.columnIndex] : { name: 'N/A', displayAt: '' })
);

interface DataSourceTableProps {
    dataSource: EditableDataSource;
    handleCellChange: (data: DataSourceCell) => void;
    handleRowInsert: (targetRow: number, type: RowInsertType) => void;
    handleRowDelete: (targetRow: number) => void;
};

const defaultProps = {
    handleCellChange: () => null,
    handleRowInsert: () => null,
    handleRowDelete: () => null,
};

const getVisibilityFilteredData = ({ rows }: EditableDataSource) => {
    if (!rows) {
        return rows;
    }
    const transpose = (matrix: DataSourceCell[][]) => {
        if (matrix.length === 0) {
            return matrix;
        }
        const newMatrix: DataSourceCell[][] = [];
        for(let col = 0; col < matrix[0].length; col++) {
            const newRow: DataSourceCell[] = [];
            for(let row = 0; row < matrix.length; row++) {
                newRow.push(matrix[row][col]);
            }
            newMatrix.push(newRow);
        }
        return newMatrix;
    }
    const filteredRows = rows.filter((row) => row.some((cell) => cell.visible));
    const transposeRows = transpose(filteredRows);
    const filteredColumns = transposeRows.filter((row) => row.some((cell) => cell.visible));
    const finalRows = transpose(filteredColumns);
    return finalRows;
}

const InitContextMenuMetadata = {
    show: false,
    x: 0,
    y: 0,
}

const DataSourceTable = ({
    dataSource,
    handleCellChange,
    handleRowInsert,
    handleRowDelete,
}: DataSourceTableProps) => {
    const [contextMenuMetaData, setContextMenuMetaData] = useState(InitContextMenuMetadata);
    const [targetRow, setTargetRow] = useState(0);
    const [data, setData] = useState<DataSourceCell[][]>([]);
    const [editing, setEditing] = useState<boolean>(false);

    const tableOnChange = (data: DataSourceCell) => {
        if (handleCellChange) {
            handleCellChange(data);
        }
        // do other stuff ???
    }

    useEffect(() => {
        const newData = getVisibilityFilteredData(dataSource);
        if (!newData || newData.length === 0) {
            return;
        }
        // quick and dirty way to tell if the data source is editable
        setEditing(newData[0][0].editable)
        setData(newData);
    }, [dataSource])

    // this is needed to make sure the DataEditorPage has _something_ to render
    //   In general the user would never see it.
    if (data?.length === 0 || !data) {
        return (
            <div className='text-center'>
                <div><EyeSlash size='30' /></div>
                <div>Please Contect Support for Access</div>
            </div>
        )
    }
    const headers = data ? getDataHeaders(dataSource, data) : dataSource.headers;

    const handleContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, row: number) => {
        e.preventDefault();
        const { pageX, pageY } = e;

        // account for the height of each of the entries and the space between them
        let height = ((5) * 24) + ((3) * 4);

        setContextMenuMetaData({
            show: true,
            x: pageX,
            y: pageY - height,
        })
        setTargetRow(row);
    }

    const contextMenuClose = () => setContextMenuMetaData(InitContextMenuMetadata)

    const handleDelete = (targetRow: number) => {
        handleRowDelete(targetRow);
    }

    const handleInsert = (targetRow: number, type: RowInsertType) => {
        handleRowInsert(targetRow, type);
    }

    const editorContextMenu = () => {
        const rowInsertClick = (type: RowInsertType) => {
            handleInsert(targetRow, type);
            contextMenuClose();
        };

        const rowDeleteClick = () => {
            handleDelete(targetRow);
            contextMenuClose();
        };

        return (
            <div className='p-2 rounded-3'>
                {<div className='my-1' onClick={() => rowInsertClick(RowInsertType.Above)}>insert row above</div>}
                {<div className='my-1' onClick={() => rowInsertClick(RowInsertType.Below)}>insert row below</div>}
                {<div className='my-1' onClick={() => rowInsertClick(RowInsertType.Clone)}>clone row</div>}
                {<div className='my-1' onClick={() => rowDeleteClick()}>delete row</div>}
            </div>
        )
    }

    return (
        <div>
            {contextMenuMetaData.show &&
                <ContextMenu
                    x={contextMenuMetaData.x}
                    y={contextMenuMetaData.y}
                    closeConextMenu={contextMenuClose}
                    className='border bg-light rounded-3 shadow-sm'
                >
                    {editing && editorContextMenu()}
                </ContextMenu>
            }
            <table className='data-source-table mt-2'>
                <thead>
                    <tr key={'header'} className='text-bg-secondary text-center'>
                        {headers.map((header, i) => (
                            <th
                                key={`${header.name}${i}`}
                                className={`data-source-table-header ${i === 0 ? '' : 'border-start border-light'} px-2`}
                            >
                                {header.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, i) => (
                        <tr
                            key={`${row ? row[0] : i}${i}`}
                            className={`${(i % 2 === 1) ? 'text bg-light' : ''}`}
                            onContextMenu={(e) => handleContextMenu(e, i)}
                        >
                            {row?.map((data) => (
                                <Cell
                                    key={`${data.rowIndex}${data.columnIndex}`}
                                    data={data}
                                    onChange={tableOnChange}
                                />
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

DataSourceTable.defaultProps = defaultProps;

export default DataSourceTable;