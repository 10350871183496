import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { MessageOptions, errorToast, warningToast } from '../components/Toasty';
import { DataSourceCell, ExcelDataSource } from '../types/Data/DataSource';

import * as xlsx from 'xlsx';

interface DataSourceDropzoneProps {
    onFileDrop: (excelDataSource: ExcelDataSource) => void,
};

const DataSourceDropzone = ({ onFileDrop }: DataSourceDropzoneProps) => {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader()

      reader.onabort = () => {
        const msg = (
          <div>
            File reading was aborted!
          </div>
        );
        const autoClose = true;
        warningToast(msg, autoClose);
      }
      reader.onerror = () => {
        const msg = (
          <div>
            File reading has failed!
          </div>
        );
        const options: MessageOptions = {
          autoClose: 3999,
          pauseOnHover: true,
          customID: undefined,
        }
        errorToast(msg, options);
      }
      console.log('')
      reader.onload = (e: any) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonArray = xlsx.utils.sheet_to_json(worksheet);

        // this regex will find any cells that are in the top row  A1, AA1, AAA1, ...
        const regex = new RegExp('^[A-Z]{1,}[1]{1}$');

        // this is needed for the case when the user may want to upload
        //   an excel document that was downloaded from Forms on Fire
        //   directly.  (Their excel documents don't populate empty cells
        //   so if we don't condition the data we may lose columns)
        const headerStrings: string[] = [];
        for(var key in worksheet) {
            if (regex.test(key)) {
                headerStrings.push(worksheet[key].v);
            }
        }

        // contains the cells that will be added back to the DS for saving
        const cells: string[][] = [];
        // loop through the json array (organized by row)
        jsonArray.forEach((obj: any) => {
            var row: string[] = [];
            headerStrings.forEach((h) => {
                row.push(obj[h] ?? '');
            })
            cells.push(row);
        })

        const excelDataSource: ExcelDataSource = {
          headers: headerStrings.map((value) => ({
            name: value,
            displayAt: 'None',
          })),
          rows: cells.map((row, r) => (
              row.map((data, c) => {
                const validations: string[] = [];
                let x: DataSourceCell;
                x = {
                  data,
                  rowIndex: r,
                  columnIndex: c,
                  modified: false,
                  editable: false,
                  visible: false,
                  selected: false,
                  validations,
                  error: false,
                }
                return x;
              })
          )),
        };

        onFileDrop(excelDataSource);
      };

      reader.readAsBinaryString(file);
    })
    
  }, [])

  /**
   * Accepted file MIME types can be found here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   */
  const {getRootProps, getInputProps} = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    }
  })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop an Excel file, or click to select a file</p>
    </div>
  )
}

export default DataSourceDropzone;