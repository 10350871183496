import React, { useCallback, useEffect, useState } from'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import * as NCRDataConstants from "../constants/NCRDataConstants";
import Header from './Header';
import Task from "../types/Task/Task";
import GetScreenFieldValue from '../functions/GetScreenFieldValue';

type AcknowledgmentProps = {
    task: Task,
    dataName: string,
    title: string,
    header?: string,
    hintText?: string,
    eventHandler?: (e: any) => void
}

const AcknowledgmentField = ({ task, dataName, title, header, hintText, eventHandler }: AcknowledgmentProps) => {

    const [acknowledgmentValue, setAcknowledgmentValue] = useState("");
    
    const setAcknowledgment = (value: string) => {
        setAcknowledgmentValue(value);

        if (eventHandler) {
            eventHandler(value);
        }
    };

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} <label className="required">*</label></label></b> <br /></div>;
    }

    const getScreenFieldValue = useCallback((dataName: string) => {
        return GetScreenFieldValue(task.screen.screenFields, dataName);
    }, [task.screen.screenFields]);

    useEffect(() => {
        var approvalValue = getScreenFieldValue(dataName);
        if (approvalValue !== "") {
            setAcknowledgmentValue(approvalValue);

            if (eventHandler) {
                eventHandler(approvalValue);
            }
        }

    }, [getScreenFieldValue, dataName, eventHandler]);

    return (
        <div>
            <Header title={header ? header : title} headerColor="white" textColor="black" />
            {titleLabel}
            <p className="HintText">{hintText}</p>
            <ButtonGroup>
                <ToggleButton
                    className={acknowledgmentValue === NCRDataConstants.ACKNOWLEDGMENT_VALUE ? "toggled-button-active" : "toggled-button"}
                    key="approve"
                    id="radio-yes"
                    type="radio"
                    name={dataName}
                    value={NCRDataConstants.ACKNOWLEDGMENT_VALUE}
                    checked={acknowledgmentValue === NCRDataConstants.ACKNOWLEDGMENT_VALUE}
                    onChange={e => setAcknowledgment(e.currentTarget.value)}
                    >
                    {NCRDataConstants.ACKNOWLEDGMENT_VALUE}
                </ToggleButton>
            </ButtonGroup>
        </div>
    );
}

export default AcknowledgmentField