import React from'react';

type MediaProps = {
    dataName: string,
    title: string,
    value: string,
    visible: boolean,
    hintText?: string
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const MediaField = ({ dataName, title, hintText, value, visible }: MediaProps) => {

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title}</label></b> <br /></div>
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{hintText}</p>
                <a href={value} target="_blank" rel="noreferrer noopener"><img src={value} alt={dataName} width="300" height="300" /></a> 
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

MediaField.defaultProps = defaultProps;

export default MediaField