type Props = {
    size: string,
    color: string,
} & typeof defaultProps;

const defaultProps = {
    size: '16',
    color: '#02569a', // "blattner" blue
};

/*
 * main collection: https://www.svgrepo.com/collection/bootstrap-ui-icons/
 * specific icon: https://www.svgrepo.com/svg/344829/files
 */
const Copy = ({ size, color }: Props) => (
    <svg
        fill={color}
        height={size}
        width={size}
        viewBox="0 0 16 16"
    >
        <path d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z" />
    </svg>
);

Copy.defaultProps = defaultProps;

export default Copy;
