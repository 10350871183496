import { Component } from 'react';
import Select from 'react-select';
import EmployeeService from "../services/EmployeeService";
import IEmployeeDetail from "../types/Employee/EmployeeDetail";
import TextField from './TextField';

type Props = {
    dataName: string,
    title: string,
    hintText?: string,
    disabled?: boolean,
    required?: boolean
    optionValue?: "EmployeeID" | "Email",
    onchange?: (e: any) => void
}

type State = {
    employees: IEmployeeDetail[]
    selectedEmployee: string,
    openMenu: boolean,
    employeeSearch: boolean
}

export default class ADSearchField extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { 
            employees: [],
            selectedEmployee: "",
            openMenu: false,
            employeeSearch: false
        }
    }

    async getEmployees(searchString: string) {
        await EmployeeService.getEmployees(searchString)
        .then((response) => {
            var employeeData = response.data;
            this.setState({                    
                employees: employeeData,
                openMenu: true
            })
        }).catch((e) => {
            console.log(e);
        })
    }

    handleAssignedToSearch = (query: string) => {
        if (query.length > 0) {
            this.getEmployees(query);
        } 
        
        if (!this.state.employeeSearch) {
            this.setState({ selectedEmployee: query });
        }
    }

    handleAssignToChange = (event: any) => {
        this.handleAssignedToSearch(event.target.value);
        
        this.setState({ 
            selectedEmployee: event.target.value,
        });
    }

    changeMenu = (event: any) => {
        var value = "";
        var search = false

        if (event) {
            value = event.value;
            search = true;
        } 

        this.setState({ 
            openMenu: false,
            selectedEmployee: value,
            employeeSearch: search
        });

        if (this.props.onchange) {
            this.props.onchange(value);
        }
    }

    hideMenu = () => {
        this.setState({openMenu: false});
    }

    render() {

        let titleLabel;
        if (this.props.title) {
            titleLabel = <div><b><label htmlFor={this.props.dataName}>{this.props.title} { this.props.required ? <label className="required">*</label> : "" }</label></b><br /></div>
        }

        return (
            <div>
                {titleLabel}
                <p className="HintText">{this.props.hintText}</p>
                { this.state.selectedEmployee.length !== 0 ? 
                    <Select
                        placeholder=""
                        name={this.props.dataName}
                        defaultInputValue={this.state.selectedEmployee}
                        autoFocus={true}
                        onInputChange={this.handleAssignedToSearch}
                        onChange={this.changeMenu}
                        onBlur={this.hideMenu}
                        options={
                                this.state.employees.map((employee) => 
                                ({ 
                                    "value" : this.props.optionValue === "Email" ? employee.employeeEmail : employee.employeeID, 
                                    "label" : `${employee.employeeName} (${employee.employeeID})`
                                }))
                        }
                        menuIsOpen={this.state.openMenu}
                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                        isClearable={true}
                        isDisabled={this.props.disabled}
                        />
                    : <TextField dataName="AssignToPlaceholder" title="" value="" readonly={false} required={this.props.required} placeholder="Search Name..."
                         onchange={this.handleAssignToChange} autoFocus={true} /> }
            </div>
        )
    }
}