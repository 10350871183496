const DOTCoordinatorJobCodes = [
  "BSC100", // Fleet Safety Specialist
  "BSC101", // Fleet Safety Specialist
];

const DOTEditorJobCodes = [
  "BFM352", // Field Engineer
  "BFM603", // Project Construction Coordinator
  "BFM602", // Project Engineer
  "BFS108", // Senior Site Inventory Specialist
  "BFS107", // Site Inventory Specialist
];

const PMSMJobCodes = [
  "BOP751", // Project Manager
  "BFM700", // Site Manager
  "BOP752", // Project Manager II
  "BOP753", // Senior Project Manager
]

export {
    DOTCoordinatorJobCodes,
    DOTEditorJobCodes,
    PMSMJobCodes,
}