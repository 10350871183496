import React, { useCallback, useEffect, useState } from'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import HiddenField from './HiddenField';
import TextArea from './TextArea';
import * as NCRDataConstants from "../constants/NCRDataConstants";
import Header from './Header';
import Task from "../types/Task/Task";
import GetScreenFieldValue from '../functions/GetScreenFieldValue';
import SelectField from './SelectField';
import TextField from './TextField';
import { useMsal } from '@azure/msal-react';

type ApproveRejectProps = {
    task: Task,
    dataName: string,
    title: string,
    hintText?: string,
    rejectToDataName?: string,
    rejectTo?: string,
    rejectionCommentsDataName: string
    approverNameDataName? :string,
    approveDateDataName?: string,
    eventHandler?: (e: any) => void,
    rejectToEventHandler?: (e: any) => void
}

const ApproveRejectField = ({ task, dataName, title, hintText, rejectToDataName, rejectTo, rejectionCommentsDataName, approverNameDataName, approveDateDataName, eventHandler, rejectToEventHandler }: ApproveRejectProps) => {
    const { accounts } = useMsal();

    const [approveValue, setApproveValue] = useState("");
    const [rejectToValue, setRejectValue] = useState("");
    
    const setApprovalValue = (value: string) => {
        setApproveValue(value);

        if (eventHandler) {
            eventHandler(value);
        }
    };

    const setRejectToValue = (event: any) => {
        const value = event.target.value;

        setRejectValue(value);

        if (rejectToEventHandler) {
            rejectToEventHandler(value);
        }
    };

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} <label className="required">*</label></label></b> <br /></div>;
    }

    const getScreenFieldValue = useCallback((dataName: string) => {
        return GetScreenFieldValue(task.screen.screenFields, dataName);
    }, [task.screen.screenFields]);

    useEffect(() => {
        // Set approval value
        var approvalValue = getScreenFieldValue(dataName);
        if (approvalValue !== "") {
            setApproveValue(approvalValue);

            if (eventHandler) {
                eventHandler(approvalValue);
            }
        }

        // Set reject to value
        var rejectValue = approvalValue === NCRDataConstants.APPROVAL_REJECT ? getScreenFieldValue(rejectToDataName ?? "") : "";
        if (rejectValue !== "") {
            setRejectValue(rejectValue);

            if (rejectToEventHandler) {
                rejectToEventHandler(rejectValue);
            }
        }

    }, [getScreenFieldValue, dataName, eventHandler, rejectToDataName, rejectToEventHandler]);

    function rejectionAssignee() {
        if(task.rejectionUsers && task.rejectionUsers.length > 0) {
            if (task.rejectionUsers.length === 1) {
                return (
                    <div>
                        <TextField dataName={NCRDataConstants.APPROVAL_REJECTION_ASSIGNEE + "_display"} title={NCRDataConstants.APPROVAL_ASSIGN_TO_TITLE} 
                            value={task.rejectionUsers[0].employeeName} readonly={true} required={true} />
                        <HiddenField dataName={NCRDataConstants.APPROVAL_REJECTION_ASSIGNEE} value={task.rejectionUsers[0].employeeEmail} />
                    </div>
                );
            } else {
                return (
                    <SelectField dataName={NCRDataConstants.APPROVAL_REJECTION_ASSIGNEE} title={NCRDataConstants.APPROVAL_ASSIGN_TO_TITLE}  
                        value={getScreenFieldValue(NCRDataConstants.APPROVAL_REJECTION_ASSIGNEE)} required={true}
                        options={task.rejectionUsers.map((user) => ({label: user.employeeName, value: user.employeeEmail}))} />
                );
            }
        } else {
            return (
                <div>
                    <TextField dataName={NCRDataConstants.APPROVAL_REJECTION_ASSIGNEE} title={NCRDataConstants.APPROVAL_ASSIGN_TO_TITLE} value="" readonly={true} />
                    <b><label className="warning">Contact Site Manager to determine and add the Assignee via the Add or Update User form in Blattner Mobile.</label></b>
                </div>
            );
        }
    }

    return (
        <div>
            <Header title={title} headerColor="white" textColor="black" />
            {titleLabel}
            <p className="HintText">{hintText}</p>
            <ButtonGroup>
                <ToggleButton
                    className={approveValue === NCRDataConstants.APPROVAL_APPROVE ? "toggled-button-active" : "toggled-button"}
                    key="approve"
                    id="radio-approve"
                    type="radio"
                    name={dataName}
                    value={NCRDataConstants.APPROVAL_APPROVE}
                    checked={approveValue === NCRDataConstants.APPROVAL_APPROVE}
                    onChange={e => setApprovalValue(e.currentTarget.value)}
                    >
                    Approve
                </ToggleButton>
                <ToggleButton
                    className={approveValue === NCRDataConstants.APPROVAL_REJECT ? "toggled-button-active" : "toggled-button"}
                    key="reject"
                    id="radio-reject"
                    type="radio"
                    name={dataName}
                    value={NCRDataConstants.APPROVAL_REJECT}
                    checked={approveValue === NCRDataConstants.APPROVAL_REJECT}
                    onChange={e => setApprovalValue(e.currentTarget.value)}
                    >
                    Reject
                </ToggleButton>
            </ButtonGroup>
            {approveValue === NCRDataConstants.APPROVAL_REJECT ? 
                <div>
                    { rejectTo ? 
                        <SelectField dataName={rejectToDataName ?? ""} title={NCRDataConstants.APPROVAL_REJECTION_REJECT_TO_TITLE} 
                            options={[{ value: NCRDataConstants.APPROVAL_REJECT_ASSIGNEE }, { value: rejectTo }]} required={true} value={rejectToValue} onchange={setRejectToValue} />
                        : null }
                    <TextArea dataName={rejectionCommentsDataName} title={NCRDataConstants.APPROVAL_REJECTION_COMMENTS_TITLE} 
                        value={getScreenFieldValue(rejectionCommentsDataName)} required={true} /> 
                    {task.ncrType.toUpperCase() !== "MATERIAL" && (!rejectTo || rejectToValue === NCRDataConstants.APPROVAL_REJECT_ASSIGNEE) ? rejectionAssignee() : null}
                </div>
                : <HiddenField dataName={rejectionCommentsDataName} value="" />}
            {approverNameDataName && approveValue === NCRDataConstants.APPROVAL_APPROVE ? <HiddenField dataName={approverNameDataName} value={accounts[0].name ?? ""} /> : null}
            {approveDateDataName && approveValue === NCRDataConstants.APPROVAL_APPROVE ? <HiddenField dataName={approveDateDataName} value={new Date().getMonth() +1 + "-" + new Date().getDate() + "-" + new Date().getFullYear()} /> : null}
        </div>
    );
}

export default ApproveRejectField