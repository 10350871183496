import React from 'react';
import { Spinner } from 'react-bootstrap';

type LoadingWrapperProps = {
    className: string,
    children: React.ReactNode,
    showLoading: boolean,
} & typeof defaultProps;

const defaultProps = {
    className: '',
    showLoading: false,
}

const LoadingWrapper = ({className, children, showLoading}: LoadingWrapperProps) => (
    showLoading ? (
        <div className={className}>
            <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    ) : (
        <>
            {children}
        </>
    )
)

LoadingWrapper.defaultProps = defaultProps;

export default LoadingWrapper;