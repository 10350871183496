type Props = {
    size: string,
    color: string,
} & typeof defaultProps;

const defaultProps = {
    size: '16',
    color: 'black',
}

/*
 * main collection: https://www.svgrepo.com/collection/brightspace-bold-interface-icons/
 * specific icon: https://www.svgrepo.com/svg/335553/edit-not-editable
 */
const Uneditable = ({ size, color }: Props) => (
    <svg
        fill={color}
        height={size}
        width={size}
        viewBox="0 0 24 24"
    >
        <path stroke="#494c4e" strokeLinecap="round" strokeWidth="2" d="M2.5 4.5l17 17" />
        <path d="M15.62 12.619L14.206 11.2l4.586-4.586-1.412-1.407-4.58 4.587-1.42-1.415 6-6 4.24 4.24-6 6zm7.085-7.084l-4.241-4.243.706-.707a2 2 0 012.83 0L23.413 2a2 2 0 010 2.828l-.706.708z" />
        <path d="M.56 24a.584.584 0 01-.19-.031.667.667 0 01-.18-.16.679.679 0 01-.16-.18.587.587 0 010-.37l2.15-4.94.671-1.409 6-6 1.416 1.416-5.757 5.755L4 19.149l-.654 1.506L4.852 20l1.068-.508 5.755-5.756 1.414 1.414-6 6-1.41.67L.74 23.969A.569.569 0 01.56 24z" />
    </svg>
)

Uneditable.defaultProps = defaultProps;

export default Uneditable;