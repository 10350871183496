import { Component } from "react";
import { MsalContext } from '@azure/msal-react';
import ITaskHistory from '../types/Task/TaskHistory';
import { ListGroup } from "react-bootstrap";
import { appInsights } from "../services/appInsights";

type Props = {
    preloadedHistorys: ITaskHistory[]
}

type State = {
    historys: ITaskHistory[]
};

export default class TaskDiscussion extends Component<Props, State> {
    static contextType = MsalContext;

    componentDidMount() {
        appInsights.trackPageView({name: "Task History page"});
    }

    render() {

        return (
            <div className="list-group" id="ideaHistorySection">

            <ListGroup>
                {this.props.preloadedHistorys.map((history, i) => {

                    var changedFrom = "";
                    var wordTo = "";
                    var fieldName = history.fieldName;

                    if(history.oldValue) {
                        changedFrom = "changed from";
                        wordTo = " to ";
                    } else {

                        if(history.fieldName === "Assigned To" && !history.oldValue) {
                            fieldName = "Claimed By";
                        } else if (history.fieldName === "Submission") {
                            fieldName = "";
                        } else if (history.fieldName === "Added New File") {
                            changedFrom = "with a file name of";
                        }
                        else {
                            changedFrom = "set to";
                        }

                    }

                    let historyDate = new Date(history.modifiedDate);
                    return (
                        <ListGroup.Item key={i}>
                            <p className="mb-1 commentListItem"><strong>{fieldName}</strong> {changedFrom} <strong>{history.oldValue}</strong>{wordTo}<strong>{history.newValue}</strong></p>
                            <div className="d-flex">
                                <small>Modified By: {history.modifiedUser}</small>
                            </div>
                            <div className="d-flex">
                                <small>{historyDate.toLocaleString('en-US', {
                                        month: 'short',
                                        day: 'numeric',
                                        year: 'numeric',
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                        timeZoneName: 'short'
                                    })}
                                </small>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>

            </div>
            
        );
    }
}