import { useState } from 'react';
import { Badge, Collapse  } from 'react-bootstrap';

import Editable from '../Icons/Editable';
import Uneditable from '../Icons/Uneditable';

import { CellValidation } from "../../types/DataSource/DataSourceRules";
import InfoCircle from '../Icons/InfoCircle';

type ValidationDisplayCellProps = {
    data: string | number,
    onChange: (data: string) => void,
    editable: boolean,
    maxInputWidth: number,
}

const ValidationDisplayCell = ({ data, onChange, editable, maxInputWidth }: ValidationDisplayCellProps) => {
    const [dataValue, setDataValue] = useState<string>(`${data}`);
    const [editing, setEditing] = useState<boolean>(false);

    // handle the double click event
    const handleDoubleClick = () => {
        if (!editable) {
            return;
        }
        setEditing(!editing);
    }

    // handle changes to the selected value
    const handleOnChange = (newDataValue: string) => {
        setDataValue(newDataValue);
    }

    // used to organize where we update how the input behaves
    const showAsInput = () => (
        <input
            style={{ maxWidth: maxInputWidth }}
            className='justify-content-center'
            onBlur={() => {
                setEditing(false);
                onChange(dataValue);
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Escape') {
                    setEditing(false);
                    onChange(dataValue);
                }
            }}
            onChange={(e) => handleOnChange(e.target.value.trimStart())}
            value={dataValue}
        />
    );

    // used to organize where we update how the data display behaves
    const showAsData = () => (
        <div>
            {data}
        </div>
    )

    // handle overarching display of the data
    const handleDisplay = () => {
        return (
            <div className='overflow-auto'>
                {editing ? showAsInput() : showAsData()}
            </div>
        )
    }

    return (
        <div
            className='row'
            onDoubleClick={() => handleDoubleClick()}
        >
            {handleDisplay()}
        </div>
    )
}


type CellValidationDisplayProps = {
    title: string,
    validations: CellValidation[] | undefined,
    editable: boolean,
    onChange: (validations: CellValidation[]) => void,
}


const CellValidationDisplay = ({ title, validations, editable, onChange }: CellValidationDisplayProps) => {

    const [editing, setEditing] = useState<boolean>(false);
    const [collapse, setCollapse] = useState<boolean>(true);
    const [localValidations, setLocalValidations] = useState<CellValidation[]>(validations ?? []);
    const [newColumnValue, setNewColumnValue] = useState<number>();
    const [newRegexValue, setNewRegexValue] = useState<string>('');

    // Used for linking to a RegEx Editor and Help page.
    const RegExTestLink = 'https://regexr.com/'

    // used to ensure that the editability is managed in a way that is consistent
    const toggleCollapse = () => {
        if(!collapse) {
            setEditing(false);
        }
        setCollapse(!collapse)
    };

    // used to ensure that the editability is managed in a way that is consistent
    const toggleEditing = () => {
        if(!editing) {
            setCollapse(false)
        }
        setEditing(!editing);
    };

    // catch changes to a column number currently in the list
    const handleColumnChange = (data: string, index: number) => {
        const newValidations = [...localValidations];
        newValidations[index].column = Number(data);
        setLocalValidations(newValidations);
    }

    // catch changes to a regex currently in the list
    const handleRegexChange = (data: string, index: number) => {
        const newValidations = [...localValidations];
        newValidations[index].regex = data;
        setLocalValidations(newValidations);
    }

    // catch the delete click on a line in the list
    const handleDelete = (index: number) => {
        const newValidations = [...localValidations];
        newValidations.splice(index, 1);
        setLocalValidations(newValidations);
        onChange(newValidations);
    }

    // used to add data to the list of cell validations
    const handleAddClick = () => {
        if (newColumnValue === undefined || !newRegexValue) {
            return;
        }
        const newValidations = [...localValidations];
        const addValidation = {
            column: newColumnValue,
            regex: newRegexValue,
        }
        newValidations.push(addValidation);
        setLocalValidations(newValidations);
        setNewColumnValue(undefined);
        setNewRegexValue('');
        onChange(newValidations);
    }

    // opens a link to a regex editor/tester and information page
    const openHelp = () => {
        window.open(RegExTestLink, '_blank')
    }

    // used to simplify a className callout for the edit button
    const hideButton = (hide: boolean) => {
        return (hide ? 'visually-hidden' : '');
    }

    return (
        <div
            className='col border shadow rounded-2 permissions-tile'
        >
            <div className='ps-3 pt-2 row'>
                <div className='col-9'>
                    <b>
                        {title}
                    </b>
                </div>
                <div
                    className='col-3 data-source-help-btn'
                    title='regex help'
                    onClick={() => openHelp()}
                >
                    <div className='row d-flex justify-content-center' >
                        <div className='col-9'>
                            RegEx Help
                        </div>
                        <div className='col d-flex justify-content-center'>
                            <InfoCircle />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='col p-2 m-2 border showdow-sm rounded-2 editable-list-tile'>
                    <div className='row'>
                        <div className='row d-flex align-items-center'>
                            <div className='col-4' onClick={() => {toggleCollapse()}}>
                                <b>Validations</b>
                            </div>
                            <div className='col-7 d-flex justify-content-left' onClick={() => {toggleCollapse()}}>
                                <Badge bg='secondary'>
                                    {validations ? validations.length : 0}
                                </Badge>
                            </div>
                            <div className='col-1 d-flex justify-content-center'>
                                <button
                                    className={`btn ${hideButton(!editable)}`}
                                    title={editing ? 'click to stop editing' : 'click to allow editing'}
                                    disabled={!editable}
                                    onClick={() => {toggleEditing()}}
                                >
                                    {editing ? <Editable /> : <Uneditable />}
                                </button>
                            </div>
                        </div>
                        <Collapse
                          in={!collapse}
                        >
                            <div>
                                <div className='row border rounded-2 shadow-sm m-2 p-2 editable-list'>
                                    <div className='col'>
                                        <div className='row border-bottom'>
                                            <div className='col-1'>
                                            </div>
                                            <div className='d-flex justify-content-center col-2'>
                                                column
                                            </div>
                                            <div className='d-flex justify-content-left col text-nowrap'
                                              style={{ overflow: 'auto'}}
                                            >
                                                regex
                                            </div>
                                        </div>
                                        <div className='col'>
                                            {
                                                (localValidations) &&
                                                localValidations
                                                    .map((v, i) => (
                                                        <div
                                                            key={`${i}_${v.column}_${v.regex}`}
                                                            className='row align-items-center border-bottom ps-3'
                                                        >
                                                            <div className='col-1'></div>
                                                            <div className='col-2 d-flex justify-content-center'>
                                                                <ValidationDisplayCell
                                                                    data={v.column}
                                                                    onChange={(d) => handleColumnChange(d, i)}
                                                                    editable={editing}
                                                                    maxInputWidth={56}
                                                                />
                                                            </div>
                                                            <div className='col d-flex justify-content-left text-nowrap'
                                                              style={{ overflow: 'auto'}}
                                                            >
                                                                <ValidationDisplayCell
                                                                    data={v.regex}
                                                                    onChange={(d) => handleRegexChange(d, i)}
                                                                    editable={editing}
                                                                    maxInputWidth={252}
                                                                />
                                                            </div>
                                                            <div className='col-1 d-flex justify-content-center align-items-center'>
                                                                <button
                                                                    className={'btn btn-sm border-0'}
                                                                    disabled={!editing}
                                                                    onClick={() => handleDelete(i)}
                                                                >
                                                                    {editing ? <>x</> : <>&nbsp;</>}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='row d-flex align-items-center'
                                >
                                    <div className='ps-5 col-3 d-flex justify-content-center'>
                                        <input
                                            style={{ maxWidth: 80 }}
                                            className='rounded border border-secondary'
                                            onChange={(e) => setNewColumnValue(Number(e.target.value.trimStart()))}
                                            placeholder={'Column'}
                                            disabled={!editing}
                                            type='number'
                                            min={0}
                                            value={newColumnValue ?? ''}
                                        />
                                    </div>
                                    <div className='col-7 d-flex justify-content-center'>
                                        <input
                                            className='rounded border border-secondary'
                                            onChange={(e) => setNewRegexValue(e.target.value.trimStart())}
                                            placeholder={'RegEx'}
                                            disabled={!editing}
                                            value={newRegexValue}
                                        />
                                    </div>
                                    <div className='col-2 d-flex justify-content-center pe-5'>
                                        <button
                                            className='btn btn-sm btn-outline-secondary px-3'
                                            onClick={handleAddClick}
                                            title='click to add selected item to the list'
                                            disabled={!editing}
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CellValidationDisplay;