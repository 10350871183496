import { useState, useEffect } from 'react';

import { Permissions } from '../../types/DataSource/DataSourceRules';
import EditableList from '../EditableList';

import ListItem from '../../types/ListItem';

interface PermissionDisplayProps {
    title?: string, // name of the permission set
    permissions?: Permissions, // permissions to display or manipulate if available
    editable: boolean, // can these particular permissions be edited
    masterJobCodeList: ListItem[], // needed to allow users to select from a list of job codes to add
    masterEmployeeList: ListItem[], // needed to allow users to select from a list of users to add
    onChange: (values: Permissions) => void, // used to handle clicking on a list item (for deleting)
};

const PermissionDisplay = ({ title, permissions, editable,  masterJobCodeList, masterEmployeeList, onChange }: PermissionDisplayProps) => {

    const [currentPermissions, setCurrentPermissions] = useState<Permissions | undefined>(permissions);
    const [currentJobCodes, setCurrentJobCodes] = useState<ListItem[]>([]);
    const [currentUsers, setCurrentUsers] = useState<ListItem[]>([]);
    const [myJobCodeKey, setJobCodeKey] = useState('jobCode_' + title + Date.now());
    const [myUserKey, setUserKey] = useState('user_' + title + Date.now());

    /**
     * this is used to convert any incoming permissions into lists that are
     *   usable in the editable list component, should only trigger when
     *   the component is loaded after page refresh
     */
    useEffect(() => {
        if (!currentPermissions || !currentPermissions.jobCodes || !currentPermissions.users) {
            return;
        }

        //  handle incoming permision job codes
        const incomingJobCodes = [...currentPermissions.jobCodes];

        const jobCodes = incomingJobCodes.map((jobCode) => ({
            value: jobCode,
            label: masterJobCodeList.find((x) => (x.value === jobCode))?.label ?? jobCode
        }));
        setCurrentJobCodes(jobCodes);

        // make sure the display updates with any new information
        setJobCodeKey('jobCode_' + title + Date.now())

        // handle incoming permission users
        const users = currentPermissions.users.map((user) => ({
            value: user,
            label: masterEmployeeList.find((x) => (x.value === user))?.label ?? user
        }));

        setCurrentUsers(users);

        // make sure the display updates with any new information
        setUserKey('user_' + title + Date.now());
    }, [currentPermissions])

    // used to remove a job code from the list of permissions
    const handleJobCodeChange = (values: ListItem[]) => {
        if (!currentPermissions) {
            return;
        }
        const jobCodes = values.map((v) => v.value);
        const newPermissions: Permissions = currentPermissions;

        newPermissions.jobCodes = jobCodes;

        setCurrentJobCodes(values);
        setCurrentPermissions(newPermissions);

        if (onChange) {
            onChange(newPermissions)
        }
    }

    // used to remove a job code from the list of permissions
    const handleUserChange = (values: ListItem[]) => {
        if (!currentPermissions) {
            return;
        }
        const users = values.map((v) => v.value);
        const newPermissions: Permissions = currentPermissions;

        newPermissions.users = users;

        setCurrentUsers(values);
        setCurrentPermissions(newPermissions);

        if (onChange) {
            onChange(newPermissions)
        }
    }

    return (
        <div
          className='border shadow rounded-2 permissions-tile'
        >
            <div className='ps-3 pt-2'>
                <b>
                    {title}
                </b>
            </div>
            <div>
                <EditableList
                    key={myJobCodeKey}
                    listTitle='Job Codes'
                    initialValues={currentJobCodes}
                    masterValues={masterJobCodeList ?? []}
                    onChange={(items: ListItem[]) => {handleJobCodeChange(items)}}
                    editable={editable}
                />
                <EditableList
                    key={myUserKey}
                    listTitle='Users'
                    initialValues={currentUsers}
                    masterValues={masterEmployeeList ?? []}
                    onChange={(items: ListItem[]) => {handleUserChange(items)}}
                    editable={editable}
                />
            </div>
        </div>
    );
};

export default PermissionDisplay;