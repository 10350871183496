import http from "../http-common";
import IEnvironment from "../types/Data/Environment"
import IFormSubmissionList from "../types/Dashboard/FormSubmissionList";
import IProjectList from "../types/Dashboard/ProjectList";
import ITaskList from "../types/Dashboard/TaskList"

class DashboardService {
    getFormSubmissions(projectID: string, sort_by: string, sort_type: string, dateFilter: string, descriptionFilter: string, modifiedFilter: string, 
        statusFilter: string, page: number, page_size: number) {
            return http.get<IFormSubmissionList>(`/getFormSubmissions?projectID=${projectID}&sort_by=${sort_by}&sort_type=${sort_type}&dateFilter=${dateFilter}` +
                `&descriptionFilter=${descriptionFilter}&modifiedFilter=${modifiedFilter}&statusFilter=${statusFilter}&page=${page}&page_size=${page_size}`);
    }

    getProjects(email: string) {
        return http.get<IProjectList>(`/getProjects?userEmail=${email}`)
    }

    getFormSubmissionTasks(entryID: string) {
        return http.get<ITaskList>(`/getFormSubmissionTasks?formEntryID=${entryID}`)
    }

    getTaskUsers(entryID: string) {
        return http.get<string[]>(`/getTaskUsers?formEntryID=${entryID}`)
    }

    getAllDashboardEnvironments() {
        return http.get<IEnvironment[]>(`/getAllDashboardEnvironments`)
    }
}

const dashboardServiceInstance = new DashboardService();

export default dashboardServiceInstance;