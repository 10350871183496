import React, { useEffect, useState } from'react';

type TextProps = {
    dataName: string,
    title: string,
    value: string,
    visible: boolean,
    readonly?: boolean,
    hintText?: string,
    required?: boolean,
    placeholder?: string,
    autoFocus?: boolean,
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const TextField = ({ dataName, title, value, visible, readonly, hintText, required, placeholder, autoFocus, onchange }: TextProps) => {

    const [updatedValue, setValue] = useState("");

    /*
        This will allow the user defined onChange to override the default
          onChange value for this TextField.  Moving to settings the
          inputHandle variable to a function eliminates one level of function
          call, which in turn reduces the size of the stack.
        useState() will make sure the function is only loaded when the page
          first renders.  This will eliminate re-setting the function for every
          charcter that is entered.
    */
    const [inputHandler] = useState(() => onchange ? onchange : (event: React.ChangeEvent<HTMLInputElement>) => { setValue(event.target.value) });

    // Set default value
    useEffect(() => {
        setValue(value);
    }, [value])

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>
    }

    return (
        visible ? 
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <input readOnly={readonly} className="Value form-control input-lg" type="text" name={dataName}
                    value={readonly ? value : updatedValue} onChange={inputHandler} required={requiredValue} placeholder={placeholder} autoFocus={autoFocus} />
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

TextField.defaultProps = defaultProps;

export default TextField