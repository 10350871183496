import React, { useEffect, useState } from'react';

type TextProps = {
    dataName: string,
    title: string,
    value: string,
    visible: boolean
    readonly?: boolean,
    timeValue?: boolean,
    hintText?: string,
    required?: boolean,
    defaultCurrentDate?: boolean,
    restrictPreviousDate?: boolean
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const DateField = ({ dataName, title, hintText, visible, readonly, timeValue, required, value, defaultCurrentDate, restrictPreviousDate, onchange }: TextProps) => {

    var today = new Date();
    var currentDate = today.getFullYear() + '-' + ("0" + (today.getMonth() + 1)).slice(-2) + '-' + ("0" + today.getDate()).slice(-2);

    const [updatedValue, setValue] = useState("");

    // This function is called when the input changes
    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredValue = event.target.value;
        setValue(enteredValue);

        if (onchange) {
            onchange(enteredValue);
        }
    };

    // This funciton is called on field exit
    const formatHandler = () => {
        const enteredValue = updatedValue;
        if (restrictPreviousDate && new Date(enteredValue) < new Date(currentDate)) {
            setValue(currentDate);

            if (onchange) {
                onchange(currentDate);
            }
        }
    }

    // Set default value
    useEffect(() => {
        var defaultDate = defaultCurrentDate && !readonly ? currentDate : value;

        setValue(defaultDate);

        if (defaultCurrentDate && !readonly) {
            if (onchange) {
                onchange(defaultDate);
            }
        }
        
    }, [currentDate, defaultCurrentDate, onchange, readonly, value])

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title)
    {
        titleLabel = <div><b><label htmlFor={dataName}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>;
    }

    return (
        visible ? 
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <input readOnly={readonly} className="Value form-control input-lg" type={timeValue ? "datetime-local" : "date"} name={dataName}
                    value={updatedValue} onChange={inputHandler} onBlur={formatHandler} required={requiredValue} />
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

DateField.defaultProps = defaultProps;

export default DateField