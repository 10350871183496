import http from "../http-common";
import ITask from "../types/Task/Task"
import ITaskAssign from "../types/Task/TaskAssign";
import ITaskDelete from "../types/Task/TaskDelete";
import ITaskComment from "../types/Task/TaskComment";
import ITaskHistory from "../types/Task/TaskHistory";
import ITaskEvent from "../types/Task/TaskEvent";

class TaskService {
  getComments(id: string) {
    return http.get<ITaskComment[]>(`/getComments?taskID=${id}`);
  }

  getHistory(id: string) {
    return http.get<ITaskHistory[]>(`/getHistory?taskID=${id}`);
  }

  getTasks(email: string) {
    return http.get<ITask[]>(`/getTasks?userEmail=${email}`);
  }

  updateBmodsNcrById(ncrID: string) { // ncrID format example "NCR 0123456789"
    return http.put<string[]>(`/updateBmodsNcrById?ncrID=${ncrID.replace(" ", "%20")}`);
  }

  getTaskDetails(id: string) {
    return http.get<ITask>(`/gettaskdetails?taskID=${id}`);
  }

  getUserEvents(email: string) {
    return http.get<ITaskEvent[]>(`/getUserEvents?userEmail=${email}`);
  }

  getDataSourceData(id: string, dataSource: string, columnIndex: string, sort: boolean = false) {
    return http.get<string[]>(`/getdatasourcedata?taskID=${id}&dsExternalID=${dataSource}&columnIndex=${columnIndex}&sort=${sort}`);
  }
  
  addComment(data: ITaskComment) {
    return http.put(`/addComment`, data, { headers: { 'Content-Type': 'application/json' }});
  }

  dismissUserEvents(id: string, email: string) {
    return http.put(`/dismissUserEvents?taskID=${id}&userEmail=${email}`);
  }

  updateTask(data: ITaskAssign) {
    return http.put(`/updateTask`, data, { headers: { 'Content-Type': 'application/json' }});
  }

  saveTask(data: FormData) {
    return http.put(`/saveTask`, data, { headers: { 'Content-Type': 'multipart/form-data' }});
  }

  runTaskEvents(id: string) {
    return http.post(`/runTaskEvents?taskID=${id}`);
  }

  submitTask(data: FormData) {
    return http.post(`/submitTask`, data, { headers: { 'Content-Type': 'multipart/form-data' }});
  }

  deleteTask(data: ITaskDelete) {
    return http.delete(`/deleteTask`, { headers: { 'Content-Type': 'application/json' }, data: data });
  }
}

const taskServiceIntance = new TaskService();

export default taskServiceIntance;