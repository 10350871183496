import React, { ForwardedRef } from'react';
import { Button } from 'react-bootstrap';

type ButtonProps = {
    dataName: string,
    title?: string,
    buttonText: string,
    color?: "blue" | "red",
    hintText?: string,
    type?: "button" | "submit" | "reset",
    size?: "lg" | "sm",
    className?: string,
    readOnly?: boolean,
    eventHandler?: (e: any) => void
}

const ButtonType = React.forwardRef(({ dataName, title, hintText, buttonText, color, type, size, className, readOnly, eventHandler }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title}</label></b> <br /></div>;
    }

    let hintTextLabel;
    if (hintText) {
        hintTextLabel = <p className="HintText">{hintText}</p>;
    }

    return (
        <div>
            {titleLabel}
            {hintTextLabel}
            <Button className={className} id={color === "red" ? "ButtonRed" : "ButtonBlue"} name={dataName} disabled={readOnly}
                onClick={eventHandler} type={type ?? "button"} size={size} ref={ref}>
                {buttonText}
            </Button>
        </div>
    );
});

export default ButtonType