import DataSourceEmployeeInfo from "../types/Employee/DataSourceEmployeeInfo";

export const isTest = (process.env.REACT_APP_IS_TEST ?? '') === 'true';

export const isAvailableNCRDay = (epoch: number = new Date().getTime()) => {
    const startDayEpoch = new Date(2024, 3, 8).getTime(); // year, month - 1, day
    const endDayEpoch = new Date(2024, 3, 18).getTime(); // year, month - 1, date
    // access is granted for a specified date range
    return (epoch > startDayEpoch && epoch < endDayEpoch);
};

export const isAvailableDSDay = (epoch: number = new Date().getTime()) => {
    const oneOffDsEpoch = new Date(2024, 3, 24).getTime(); // year, month - 1, date
    // access is granted for one day
    return ((epoch > oneOffDsEpoch) && (epoch < (oneOffDsEpoch + (24 * 60 * 60 * 1000))));
};

export const checkIfOneOffNCR = (user: DataSourceEmployeeInfo) => {
    return (isTest || reliabilityEngineerAccess(user.jobCode))
    && isAvailableNCRDay();
};

export const hasRuleEditorAccess = (user: DataSourceEmployeeInfo) => (
    (reliabilityEngineerAccess(user.jobCode)
    || (developerAccess(user.jobCode) && isTest))
);

export const reliabilityEngineerAccess = (jobCode: string) => {
    const reJobCodeList = ['BIT357', 'BIT307'];
    return(reJobCodeList.includes(jobCode));
};

export const developerAccess = (jobCode: string) => {
    const developerJobCodeList = ['BIT406', 'BIT354', 'BIT306'];
    return(developerJobCodeList.includes(jobCode));
};

export const businessSystemAnalystAccess = (jobCode: string) => {
    const businessSystemAnalystJobCodeList = ['BIT400', 'BIT350', 'BIT300'];
    return(businessSystemAnalystJobCodeList.includes(jobCode))
};

export const hasAnyAdminAccess = (user: DataSourceEmployeeInfo) => {
    return (
      reliabilityEngineerAccess(user.jobCode)
      || isTest
    );
};
