import xlsx from "xlsx-js-style";

import { convertToDS } from '../functions/DataSource';
import { EditableDataSource } from '../types/Data/DataSource';
import Upload from "./Icons/Upload";

interface ExportToExcelButtonProp {
    onClick?: () => void | undefined,
    ds?: EditableDataSource | undefined,
};

/**
 * More information on excel formatting and data types can be found here:
 * http://officeopenxml.com/anatomyofOOXML-xlsx.php
 */

const ExportToExcelButton = ({ ds, onClick }: ExportToExcelButtonProp) => {
    const getButtonColoring = () => {
        return 'btn-blattnerblue';
    };

    const xlsxWriteFile = () => {
        if (!ds) {
            return;
        }
        const headerStyle = {
            fill: {
                fgColor: { rgb: '00008B' }
            },
            font: {
                name: 'Tahoma',
                bold: true,
                color: {rgb: 'FFFFFF'},
                sz: 11,
            }
        };

        const rowStyle = {
            font: {
                name: 'Tahoma',
                bold: false,
                color: {rgb: '000000'},
                sz: 11,
            }
        };

        let rows:any[] = [];

        const newDataSource = convertToDS(ds);
        rows.push(newDataSource.headers.map(({name: v}) => ({
            v, // value of the cell
            t: 's', // type of cell value, 's' is string
            s: headerStyle, // styling for the cell
        })));

        newDataSource.rows.forEach(row => {
            rows.push(row.map(v => ({
                v, // value of the cell
                t: 's', // type of cell value, 's' is string
                s: rowStyle, // styling for the cell
            })));
        });

        const timestamp = new Date().getTime();
        const fileName = `${ds.name}-${timestamp}.xlsx`;

        const ws = xlsx.utils.aoa_to_sheet(rows);
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, "Rows");
        xlsx.writeFile(wb, fileName);
    };

    const handleOnClick = () => {
        xlsxWriteFile();

        if(onClick) {
            onClick();
        }
    };

    return (
        <div>
            <button
                type='button'
                className={`btn data-source-btn ${getButtonColoring()}`}
                onClick={handleOnClick}
            >
                Export
                &nbsp;
                <Upload color="white" />
            </button>
        </div>
    );
}

export default ExportToExcelButton;