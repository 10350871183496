import { useEffect } from "react";

const useOutsideAlert = (ref: any, action: () => void) => {
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (ref?.current && !ref?.current.contains(e.target)) {
                action();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return (() => {
            document.removeEventListener('mousedown', handleClickOutside);
        });
    }, [ref, action]);
};

export default useOutsideAlert;