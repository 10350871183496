import {
    DataSource,
    DataSourceCell,
    EditableDataSource
} from '../types/Data/DataSource';

export const convertToEDS = (ds: DataSource) => ({
    id: ds.id,
    name: ds.name,
    externalId: ds.externalId,
    headers: ds.headers,
    rows: ds.rows.map((row, rowIndex) => (
        row.map((datum, columnIndex) => ({
            data: datum,
            rowIndex: rowIndex,
            columnIndex: columnIndex,
            modified: false,
            editable: false,
            visible: false,
            selected: false,
            validations: [],
            error: false,
        } as DataSourceCell))
    )),
    totalRows: ds.totalRows,
    lastUpdated: ds.lastUpdated,
    companyId: ds.companyId,
});

// this will be needed for saving the data
export const convertToDS = (eds: EditableDataSource) => ({
    id: eds.id,
    name: eds.name,
    externalId: eds.externalId,
    headers: eds.headers,
    rows: (eds.rows ? eds.rows.map((row) => row.map((datum) => (datum.data))) : []),
    totalRows: eds.rows ? eds.rows.length : eds.totalRows,
    lastUpdated: eds.lastUpdated,
    companyId: eds.companyId,
});
