import { useState } from "react";
import CloseButton from 'react-bootstrap/CloseButton';
import { Modal } from "react-bootstrap";

import DataSourceDropzone from "./DataSourceDropzone";
import Download from "./Icons/Download";
import { ExcelDataSource } from "../types/Data/DataSource";

interface ImportFromExcelButtonProps {
  onUpload: (excelDataSource: ExcelDataSource) => void,
}

const ImportFromExcelButton = ({ onUpload }: ImportFromExcelButtonProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Modal 
        show={showModal}
      >
        <Modal.Header>
          <div className='col'>
            <div className='row'>
              <div className='col' />
              <div className='col-9 h2 text-center'>
                Import Data Source
              </div>
              <div className='col'>
                <CloseButton 
                  onClick={() => {setShowModal(false)}}
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='text-center text-bold'>
            <DataSourceDropzone 
              onFileDrop={(data) => {
                onUpload(data);
                setShowModal(false);
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
      <button
        type='button'
        className='btn data-source-btn btn-warning'
        onClick={() => {setShowModal(true)}}
      >
        Import
        &nbsp;
        <Download />
      </button>
    </div>
  );
}

export default ImportFromExcelButton;