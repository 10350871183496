import DataSourceEmployeeInfo from '../types/Employee/DataSourceEmployeeInfo';

import UserDataDisplay from '../components/UserDataDisplay';
import { Container } from 'react-bootstrap';

interface UserInfoPageProps {
    userData: DataSourceEmployeeInfo,
};

const UserInfoPage = ({ userData }: UserInfoPageProps) => {
    return(
        <Container>
            <div
                className='row pt-4'
            >
                <div className='col border rounded-2 shadow'>
                    <div className='row mx-1 mt-4 pb-2 user-data-page-header border-bottom border-4'>
                        <div className='col-1'></div>
                        <div className='col'>
                            User Information
                        </div>
                    </div>
                    <div
                        className='row mx-2'
                    >
                        <UserDataDisplay userData={userData} />
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default UserInfoPage;