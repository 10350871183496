import http from "../http-common";
import IEmployeeDetail from "../types/Employee/EmployeeDetail";
import BoomiEmployee from "../types/Employee/BoomiEmployee";
import DataSourceEmployeeInfo from "../types/Employee/DataSourceEmployeeInfo";

class EmployeeService {
  getEmployees(searchString: string) {
    return http.get<IEmployeeDetail[]>(`/getEmployees?q=${searchString}`);
  }

  getEmployeesAll(searchString: string) {
    return http.get<IEmployeeDetail[]>(`/getEmployeesAll?q=${searchString}`);
  }

  getNameByID(searchString: string) {
    return http.get<string>(`/getNameByID?q=${searchString}`);
  }

  getEmployeeFOFData(email: string) {
    return http.get<BoomiEmployee>(`/getEmployeeFOFData?email=${email}`);
  }

  getDataSourceEmployeeInfo(email: string) {
    return http.get<DataSourceEmployeeInfo>(`/getDataSourceEmployeeInfo?email=${email}`);
  }
}

const employeeServiceInstance = new EmployeeService();

export default employeeServiceInstance;