type Props = {
    size: string,
    color: string,
} & typeof defaultProps;

const defaultProps = {
    size: '16',
    color: '#02569a', // "blattner" blue
}

/*
 * main collection: https://www.svgrepo.com/collection/asoka-interface-icons/
 * specific icon: https://www.svgrepo.com/svg/489764/open-in-new-window
 */
const NewTab = ({ size, color }: Props) => (
    <svg
        fill="none"
        height={size}
        width={size}
        viewBox="0 0 24 24"
    >
        <path 
            stroke={color}
            strokeWidth="2"
            d="M5 12V6C5 5.44772 5.44772 5 6 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H12M8.11111 12H12M12 12V15.8889M12 12L5 19"
        />
    </svg>
)

NewTab.defaultProps = defaultProps;

export default NewTab;