import { useState } from 'react';
import { DataSourceCell } from '../../types/Data/DataSource';
import EyeSlash from '../Icons/EyeSlash';
import Editable from '../Icons/Editable';
import Uneditable from '../Icons/Uneditable';

import '../../styles/Common.css';

type CellProps = {
    data: DataSourceCell,
    onChange: (data: DataSourceCell) => void,
    onMouseOver: (data: DataSourceCell) => void,
    onMouseDown: (data: DataSourceCell) => void,
    onMouseUp: (data: DataSourceCell) => void,
} & typeof defaultCellProps;

const defaultCellProps = {
    onChange: (data: DataSourceCell) => {},
    onMouseOver: (data: DataSourceCell) => {},
    onMouseDown: (data: DataSourceCell) => {},
    onMouseUp: (data: DataSourceCell) => {},
};

const Cell = ({ data, onChange, onMouseOver, onMouseDown, onMouseUp }: CellProps) => {
    const modifiedColor = 'bg-success bg-opacity-25';
    const selectedColor = 'bg-info bg-opacity-25';
    const editableStyle = 'border';
    const errorColor = 'bg-danger bg-opacity-25';
    const tableCellSpacing = 'pt-1 mt-1';

    const [originalData] = useState<string>(data.data);
    const [dataValue, setDataValue] = useState<DataSourceCell>(data);
    const [isInput, setIsInput] = useState<boolean>(false);

    const cellOnChange = (dataChange: string) => {
        const newDataSourceCell: DataSourceCell = {
            data: dataChange,
            rowIndex: dataValue.rowIndex,
            columnIndex: dataValue.columnIndex,
            modified: originalData !== dataChange,
            editable: dataValue.editable,
            visible: dataValue.visible,
            selected: dataValue.selected,
            validations: dataValue.validations,
            error: dataValue.error,
        };
        setDataValue(newDataSourceCell);
    };

    const showAsInput = () => (
        <input
            className={`${tableCellSpacing} data-source-table-cell-input pt-1 mt-1`}
            type='text'
            onBlur={() => {
                setIsInput(false)
                onChange(dataValue)
            }}
            onChange={(e) => cellOnChange(e.target.value.trimStart())}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Escape') {
                    setIsInput(false);
                    onChange(dataValue);
                }
            }}
            value={dataValue.data}
        />
    );

    const showAsValue = () => (
        <div className={`${tableCellSpacing}`}>
            {(dataValue.data) ? dataValue.data : (dataValue.editable) ? <Editable size='18' /> : <Uneditable size='18'/>}
        </div>
    );

    const handleDoubleclick = () => {
        if(data.editable) {
            setIsInput(!isInput);
        }
    };

    const handleDisplay = () => {
        if (!dataValue.visible) {
            return (<EyeSlash size='18' />);
        }

        return (
            <div className='overflow-auto'>
                {isInput ? showAsInput() : showAsValue()}
            </div>
        );
    };

    const myOnMouseOver = () => {
        onMouseOver(data);
    };

    const myOnMouseDown = () => {
        onMouseDown(data);
    };

    const myOnMouseUp = () => {
        onMouseUp(data);
    };

    const getColor = () => {
        if (dataValue.error && dataValue.editable) {
            return errorColor;
        }
        if (dataValue.modified) {
            return modifiedColor;
        }
        if (dataValue.selected) {
            return selectedColor;
        }
        return '';
    };

    return (
        <td
            className={`data-source-table-data text-center ${getColor()} ${dataValue.editable && dataValue.visible ? editableStyle : ''} px-2 mx-2`}
            onDoubleClick={() => handleDoubleclick()}
            onMouseOver={myOnMouseOver}
            onMouseDown={myOnMouseDown}
            onMouseUp={myOnMouseUp}
        >
            {handleDisplay()}
        </td>
    );
};

Cell.defaultProps = defaultCellProps;

export default Cell;