import { RoleGroup } from "../enums/RoleGroup";
import http from "../http-common";
import RoleGroupList from "../types/RoleGroup/RoleGroupList";
import RoleGroupMemberList from "../types/RoleGroup/RoleGroupMemberList";

class RoleGroupService {
    getGroupMembers(projectID: string, group: RoleGroup, userEmail: string) {
        return http.get<RoleGroupMemberList>(`/getGroupMembers?projectID=${projectID}&group=${group}&userEmail=${userEmail}`);
    }

    getAllGroupMembers(projectID: string, userEmail: string) {
        return http.get<RoleGroupMemberList[]>(`/getAllGroupMembers?projectID=${projectID}&userEmail=${userEmail}`);
    }

    updateGroupMembers(data: RoleGroupList) {
        return http.post(`/updateGroupMembers`, data, { headers: { 'Content-Type': 'application/json' }});
    }
}

const roleGroupServiceInstance = new RoleGroupService();

export default roleGroupServiceInstance;