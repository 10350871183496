import React from'react';

type FileProps = {
    title: string,
    value: string,
    visible: boolean
    hintText?: string,
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const FileField = ({ title, value, visible, hintText }: FileProps) => {

    let titleLabel;
    if (title) {
        titleLabel = <div><b><label>{title}</label></b> <br /></div>
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{hintText}</p>
                <a href={value} target="_blank" rel="noreferrer noopener">Click here to download</a> 
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

FileField.defaultProps = defaultProps;

export default FileField