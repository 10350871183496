import { useEffect, useState } from'react';
import IProjectList from '../types/Dashboard/ProjectList';
import SelectField from './SelectField';
import { withCookies, Cookies } from 'react-cookie';

type Props = {
    projectList: IProjectList;
    cookies: Cookies;
    cookieName: string;
    selectedProjectHandler?: (newProject: string) => void;
}

const ProjectDropdown = ({ projectList, cookies, cookieName, selectedProjectHandler }: Props) => {

    const [selectedProject, setSelectedProject] = useState<string>("");

    // Set default value
    useEffect(() => {
        const project = cookies.get(cookieName);

        if (project) {
            setSelectedProject(project);

            if (selectedProjectHandler) {
                selectedProjectHandler(project)
            }
        }
    }, [cookies, selectedProjectHandler])

    const handleProjectChange = (event: any) => {
        const newValue = event.target.value;
        
        setSelectedProject(newValue);

        if (selectedProjectHandler) {
            selectedProjectHandler(newValue);
        }

        cookies.set(cookieName, newValue, { path: '/' });
      }

    return ( 
        <SelectField 
            dataName="projectSelect" 
            title="Project" 
            options={projectList.projects.map((project) => { return { label: `${project.projectNumber} - ${project.projectName}`, value: project.projectID}})} 
            value={selectedProject}
            onchange={handleProjectChange} />
    );
}

export default withCookies(ProjectDropdown);