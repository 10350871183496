import { useEffect, useState } from 'react';
import DataSourceEmployeeInfo from '../types/Employee/DataSourceEmployeeInfo';

import Copy from './Icons/Copy';


type DataDisplayRowProps = {
    data: string,
};

const DataDisplayRow = ({ data }: DataDisplayRowProps) => {
    const [showCopy, setShowCopy] = useState<boolean>(false);

    return(
        <div
            className='row'
            onMouseEnter={() => setShowCopy(true)}
            onMouseLeave={() => setShowCopy(false)}
        >
            <div className='col-11'>
                {data}
            </div>
            <div
                className='col-1'
                onClick={() => navigator.clipboard.writeText(data)}
                title={(showCopy && window.isSecureContext) ? 'copy text' : ''}
            >
                { showCopy && window.isSecureContext && <Copy size='12' />}
            </div>
        </div>
    );
};

type UserDataDisplayProps = {
    userData: DataSourceEmployeeInfo,
    asModal: boolean,
};

const defaultProps = {
    asModal: false,
};

const UserDataDisplay = ({ userData, asModal }: UserDataDisplayProps) => {
    const labelStyling = 'col-3 border-bottom';
    const dataStyling = 'col-7 border-bottom overflow-auto';
    const bufferStyling = 'col-1';
    return (
        <div>
            <div className='row'>
                <div className={bufferStyling}></div>
                <div className={labelStyling}>Company</div>
                <div className={dataStyling}><DataDisplayRow data={userData.company} /></div>
                <div className={bufferStyling}></div>
            </div>
            {
                !asModal &&
                <div className='row'>
                    <div className={bufferStyling}></div>
                    <div className={labelStyling}>Business Unit</div>
                    <div className={dataStyling}><DataDisplayRow data={userData.businessUnit} /></div>
                    <div className={bufferStyling}></div>
                </div>
            }
            <div className='row'>
                <div className={bufferStyling}></div>
                <div className={labelStyling}>Email</div>
                <div className={dataStyling}><DataDisplayRow data={userData.email} /></div>
                <div className={bufferStyling}></div>
            </div>
            <div className='row'>
                <div className={bufferStyling}></div>
                <div className={labelStyling}>Employee Number</div>
                <div className={dataStyling}><DataDisplayRow data={userData.employeeID.toString()} /></div>
                <div className={bufferStyling}></div>
            </div>
            {
                !asModal &&
                <div className='row'>
                    <div className={bufferStyling}></div>
                    <div className={labelStyling}>Job Code</div>
                    <div className={dataStyling}><DataDisplayRow data={userData.jobCode} /></div>
                    <div className={bufferStyling}></div>
                </div>
            }
            <div className='row'>
                <div className={bufferStyling}></div>
                <div className={labelStyling}>Job Title</div>
                <div className={dataStyling}><DataDisplayRow data={userData.title} /></div>
                <div className={bufferStyling}></div>
            </div>
            {
                !asModal &&
                <div className='row'>
                    <div className={bufferStyling}></div>
                    <div className='col-3'>AD Groups</div>
                    <div className={`${dataStyling} user-info-page-adgroup`}>
                        {
                            userData.adGroups.map((group, i) => (
                                <div
                                    key={`${group}${i}`}
                                    className={`row ${i === (userData.adGroups.length - 1) ? '' : 'border-bottom'}`}
                                >
                                    <div className='col' title={group.id}>
                                        {group.displayName}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={bufferStyling}></div>
                </div>
            }
        </div>
    );
};

UserDataDisplay.defaultProps = defaultProps;

export default UserDataDisplay;