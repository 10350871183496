import React, { useState } from'react';
import FormatNumber from '../functions/FormatNumber';

type NumberProps = {
    dataName: string,
    title: string,
    value: string,
    visible: boolean
    readonly?: boolean,
    hintText?: string,
    required?: boolean,
    decimals?: number,
    onchange?: (e: any) => void
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const NumberField = ({ dataName, title, value, visible, readonly, hintText, required, decimals = 0, onchange }: NumberProps) => {

    var formattedValue = decimals > 0 ? FormatNumber(parseFloat(value), decimals) : value;
    const [updatedValue, setValue] = useState(value === "" ? FormatNumber(0, decimals) : formattedValue)

    // This fuction is called when the input changes
    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);

        if (onchange) {
            onchange(event);
        }
    };

    const onBlurHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        var enteredValue = event.target.value;

        var numberValue = parseFloat(enteredValue);
        if (numberValue) {
            setValue(FormatNumber(numberValue, decimals));
        }
    }

    let requiredValue = readonly ? false : required;
    let titleLabel;
    if (title) {
        titleLabel = <div><b><label htmlFor={dataName}>{title} { requiredValue ? <label className="required">*</label> : "" }</label></b><br /></div>;
    }

    return (
        visible ?
            <div className="form-group ComponentDivs" >
                {titleLabel}
                <p className="HintText">{readonly ? "" : hintText}</p>
                <input readOnly={readonly} className="Value form-control input-lg" type="number" name={dataName} step={Math.pow(10, -decimals)}
                    value={readonly ? formattedValue : updatedValue} onChange={inputHandler} onBlur={onBlurHandler} required={requiredValue} />
            </div>
        : <></> // If not visible, return empty element instead of null
    );
}

NumberField.defaultProps = defaultProps;

export default NumberField