import React, { Component } from'react';
import { Modal, Table } from 'react-bootstrap';
import { appInsights } from '../services/appInsights';
import { MsalContext } from "@azure/msal-react";
import DashboardService from '../services/DashboardService';
import ITaskList from '../types/Dashboard/TaskList'
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';

type Props = {}

type State = {
    entryID: string,
    tasksLoading: boolean,
    taskList: ITaskList,
    openGroupUserModal: boolean,
    groupUsers: string[],
    searchParams: URLSearchParams
}

export default class DashboardTaskDetails extends Component<Props, State> {
    static contextType = MsalContext;

    constructor(props: any) {
        super(props);

        const searchParams = new URLSearchParams(props.location.search);

        this.state = {
            entryID: searchParams.get("entryID") ?? "",
            tasksLoading: true,
            taskList: {
                formDescription: "",
                tasks: [],
            },
            openGroupUserModal: false,
            groupUsers: [],
            searchParams: searchParams
        }
    };

    async componentDidMount() {
        appInsights.trackPageView({name: "Dashboard Task Details Page"});
        this.getTasks(this.state.entryID);
      }

    async getTasks(entryID: string) {
        await DashboardService.getFormSubmissionTasks(entryID)
          .then((response) => {
                this.setState({
                    tasksLoading: false,
                    taskList: response.data
                });
            })
            .catch((e) => {
                this.setState({
                    tasksLoading: false
                });
            });
      }

    onGroupUserModal = (taskID: string) => {
        var task = this.state.taskList.tasks.find(x => x.id === taskID);

        if (task) {
            this.setState({ 
                openGroupUserModal: true,
                groupUsers: task.users
            });
        }
      };

    onCloseGroupUserModal = () => {
        this.setState({ 
            openGroupUserModal: false,
            groupUsers: []
        });
      };

    getQueryString() {
        var queryString = this.state.searchParams
        queryString.delete("entryID");
        return queryString.toString();
    }

    render() {    
        const {
            tasksLoading,
            taskList,
            openGroupUserModal,
            groupUsers
          } = this.state;

        if (tasksLoading) {
            return (
                <Loading showLoading={true} />
            )
        }
         
        const TaskItems = taskList.tasks.length > 0 ? 
                taskList.tasks.map((task) =>
                    <tr key={task.id}>
                        <td>{new Date(task.dueDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'})}</td>
                        <td>{task.description}</td>
                        <td>{task.users.length > 1 ? 
                            <button onClick={() => this.onGroupUserModal(task.id)} className="ButtonLink">Group</button> : 
                            task.users[0]}
                        </td>
                        <td>{task.status}</td>
                    </tr>
                ) : <tr>
                        <td colSpan={100}>
                            <b>No Tasks Found</b>
                        </td>
                    </tr>;

        return (
            <div>
                <Modal show={openGroupUserModal} onHide={this.onCloseGroupUserModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Task Assigned To:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <ul>
                                {groupUsers.map((user, i) => 
                                    <li key={i}>{user}</li>
                                )}
                            </ul>
                        </div>
                    </Modal.Body>
                </Modal>

                <Link to={{pathname: '/dashboard', search: `?${this.getQueryString()}`} }>Go Back</Link>
                <h1 className="header mt-5 text-center">Task Details</h1>
                <div className="ComponentDivs">
                    <b>Form Description: </b>{taskList.formDescription}
                </div>
                <div>
                    <Table>
                        <thead>
                            <tr>
                                <th style={{width: 75}}>Due Date</th>
                                <th style={{width: 275}}>Task Description</th>
                                <th style={{width: 125}}>Assigned To</th>
                                <th style={{width: 125}}>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {TaskItems}
                        </tbody>
                    </Table>
                </div>
            </div>
        );
    }
}