const JobCodeList = () => ([
    {   code: '',
        name: 'None',
    },
    {
        code: 'BIT357', // old 'ITT751',
        name: 'Reliability Engineer',
    },
    {
        code: 'BIT307', // old 'ITT747',
        name: 'Associate Reliability Engineer',
    },
    {
        code: 'BIT406', // old 'ITT736',
        name: 'Senior Application Developer',
    },
    {
        code: 'BIT354', // old 'ITT728',
        name: 'Application Developer',
    },
    {
        code: 'BIT306', // old 'ITT727',
        name: 'Associate Application Developer',
    },
    {
        code: 'BIT400', // old 'ITT710',
        name: 'Senior Business System Analyst',
    },
    {
        code: 'BIT350', // old 'ITT707',
        name: 'Business System Analyst',
    },
    {
        code: 'BIT300', // old 'ITT702',
        name: 'Associate Business System Analyst',
    },
    {
        code: 'BFM600', // old 'OPS030',
        name: 'Quality Superintendent',
    },
    {
        code: 'BFM601', // old 'OPS033',
        name: 'Quality Superintendent II',
    },
    {
        code: 'BQU400', // old 'QUA713',
        name: 'Senior Quality System Analyst',
    },
    {
        code: 'BQU351', // old 'QUA705',
        name: 'Quality System Analyst',
    },
    {
        code: 'BQU650', // old 'QUA721',
        name: 'Manager I - Quality',
    },
    {
        code: 'BTO100', // old 'QUA502',
        name: 'Field Process Improvement Specialist',
    },
    {
        code: 'BQU100', // old 'QUA504',
        name: 'Quality Specialist',
    },
    {
        code: 'BHR301', // old 'HRR535',
        name: 'Associate Talent Development Partner',
    },
])

const List = JobCodeList();

export default List;