import http from "../http-common";
import FormEntry from "../types/FormEntry/FormEntry";

class FormEntryService {
  submit(data: FormEntry) {
    return http.post(`/formentry`, data, { headers: { 'Content-Type': 'application/json' }});
  }
}

const formEntryServiceInstance = new FormEntryService();

export default formEntryServiceInstance;