import Editable from '../../components/Icons/Editable';
import Uneditable from '../../components/Icons/Uneditable';
import UnderConstruction from '../../components/Icons/UnderConstruction';

const blattnerBlue = '#02569a';

const RuleEditorHelp = () => (
    <div className='text-center'>
        <div>
            <h2>
                Under Construction
            </h2>
        </div>
        <div>
            <UnderConstruction size={'400'} />
        </div>
        <div>
            <h4>
                Come Back Soon!
            </h4>
        </div>
    </div>
);

export default RuleEditorHelp;