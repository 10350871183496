export const enum Stage {
    Unknown = 0, // Leave first as default
    Started = 1,
    Submitted = 2,
    Assigned = 3,
    EngineeringReview = 4,
    SupplyChainReview = 5,
    SMPMApproval = 6,
    DCAcknowledgment = 7,
    QualityApproval = 8,
    QualityRefinement = 9,
    QualitExpediting = 10,
    CorporateAnalysis = 11,
    Implementation = 12,
    Complete = 13
}

export const StageLabel: { [key in Stage]: string } = {
    [Stage.Unknown]: "Unknown",
    [Stage.Started]: "Started",
    [Stage.Submitted]: "Submitted",
    [Stage.Assigned]: "Assigned",
    [Stage.EngineeringReview]: "Engineering Review",
    [Stage.SupplyChainReview]: "Supply Chain Review",
    [Stage.SMPMApproval]: "SM/PM Approval",
    [Stage.DCAcknowledgment]: "DC Acknowledgment",
    [Stage.QualityApproval]: "Quality Approval",
    [Stage.QualityRefinement]: "Quality Refinement",
    [Stage.QualitExpediting]: "Quality Expediting",
    [Stage.CorporateAnalysis]: "Corporate Analysis",
    [Stage.Implementation]: "Implementation",
    [Stage.Complete]: "Complete"
};