import React from'react';

type TextProps = {
    dataName: string,
    value: string
}

const HiddenField = ({ dataName, value }: TextProps) => {

    return (
        <div className="form-group ComponentDivs" >
            <input type="hidden" name={dataName} value={value} />
        </div>
    );
}

export default HiddenField