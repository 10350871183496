import React, { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination";

type PaginationProps = {
    currentPage: number,
    pages: number,
    onPageChange: ((newPage: number) => void)
}
const MyPagination = ({ currentPage, pages, onPageChange }: PaginationProps) => {
    const [totalPages, setTotalPages] = useState<number>(1);
    
    // Set default value
    useEffect(() => {
        var total = pages > 0 ? pages : 1;

        setTotalPages(total);
    }, [pages])
      
    return (
        <Pagination>
            {/* Show first page icon, disabled if on the first page */}
            <Pagination.First key="firstpage" disabled={currentPage === 1} onClick={() => onPageChange(1)} />

            {/* Show previous page icon, disabled if on the first page */}
            <Pagination.Prev key="prev" disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)} />

            {/* Show the number 1, only shows if current page is greater than 3 */}
            {currentPage > 3 ? <Pagination.Item key="first" onClick={() => onPageChange(1)}>{1}</Pagination.Item> : null }

            {/* Show ellipsis icon, only shows if current page is greater than 4 */}
            {currentPage > 4 ? <Pagination.Ellipsis key={currentPage - 3} onClick={() => onPageChange(currentPage - 3)} /> : null }

            {/* Show number for page number of 2 pages before current page, only shows if current page is greater than 2 */}
            {currentPage > 2 ? <Pagination.Item key={currentPage - 2} onClick={() => onPageChange(currentPage - 2)}>{currentPage - 2}</Pagination.Item> : null }

            {/* Show number for page number of 1 page before current page, only shows if current page is greater than 1 */}
            {currentPage > 1 ? <Pagination.Item key={currentPage - 1} onClick={() => onPageChange(currentPage - 1)}>{currentPage - 1}</Pagination.Item> : null }

            {/* Show number for current page, always will show */}
            <Pagination.Item key={currentPage} active>{currentPage}</Pagination.Item>

            {/* Show number for page number of 1 page after current page, only shows if current page is less than or equal to total pages - 1 */}
            {currentPage <= (totalPages - 1) ? <Pagination.Item key={currentPage + 1} onClick={() => onPageChange(currentPage + 1)}>{currentPage + 1}</Pagination.Item> : null }

            {/* Show number for page number of 2 pages after current page, only shows if current page is less than or equal to total pages - 2 */}
            {currentPage <= (totalPages - 2) ? <Pagination.Item key={currentPage + 2} onClick={() => onPageChange(currentPage + 2)}>{currentPage + 2}</Pagination.Item> : null }

            {/* Show ellipsis icon, only shows if current page is less than or equal to total pages - 4 */}
            {currentPage <= (totalPages - 4) ? <Pagination.Ellipsis key={currentPage + 3} onClick={() => onPageChange(currentPage + 3)} /> : null }

            {/* Show the number for the highest page, only shows if current page is less than or equal to total pages - 3 */}
            {currentPage <= (totalPages - 3) ? <Pagination.Item key="last" onClick={() => onPageChange(totalPages)}>{totalPages}</Pagination.Item> : null }

            {/* Show next page icon, disabled if on the last page */}
            <Pagination.Next disabled={currentPage === totalPages} key="next" onClick={() => onPageChange(currentPage + 1)} />

            {/* Show last page icon, disabled if on the last page */}
            <Pagination.Last disabled={currentPage === totalPages} key="lastpage" onClick={() => onPageChange((totalPages))} />
        </Pagination>
    );
}

export default MyPagination