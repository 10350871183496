type Props = {
    size: string,
    color: string,
} & typeof defaultProps;

const defaultProps = {
    size: '16',
    color: 'black'
};

/*
 * main collection: https://www.svgrepo.com/collection/bootstrap-ui-icons/
 * specific icon: https://www.svgrepo.com/svg/345265/upload
 */
const Upload = ({ size, color }: Props) => (
    <svg
        fill={color}
        height={size}
        width={size}
        viewBox="0 0 16 16"
    >
        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
    </svg>
)

Upload.defaultProps = defaultProps;

export default Upload;