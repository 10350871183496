const DataSourceMessage = (dataSourceName: string, msg: React.ReactNode | string) => (
    <div>
        <p className='text-center border-bottom fs-3'>
            {dataSourceName}
        </p>
        <div>
            {msg}
        </div>
    </div>
);

export default DataSourceMessage;