import { Component } from "react";
import TextField from './TextField';
import FileUploadField from "./FileUploadField";
import Task from "../types/Task/Task";
import Header from "./Header";
import NumberField from "./NumberField";
import FormatNumber from "../functions/FormatNumber";
import GetScreenFieldValue from "../functions/GetScreenFieldValue";
import HiddenField from "./HiddenField";
import { MsalContext } from "@azure/msal-react";
import * as NCRDataConstants from "../constants/NCRDataConstants";
import FileField from "./FileField";

type Props = {
    task: Task,
    files: File[],
    fileAddHandler?: (e: any) => void,
    fileRemoveHandler?: (e: any) => void
};

type State = {
    blattnerCosts: { [name: string]: number },
    nonBlattnerCosts: { [name: string]: number },
    blattnerTotalCosts: number,
    nonBlattnerTotalCosts: number,
};

export default class SupplyChainReview extends Component<Props, State> {
    static contextType = MsalContext;

    constructor(props: any) {
        super(props);
        this.state = { 
            blattnerCosts: {
                [NCRDataConstants.INVESTIGATION_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.INVESTIGATION_BLATTNER_COST)) || 0,
                [NCRDataConstants.WORK_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.WORK_BLATTNER_COST)) || 0,
                [NCRDataConstants.ADDITIONAL_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.ADDITIONAL_BLATTNER_COST)) || 0,
                [NCRDataConstants.REMOVAL_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.REMOVAL_BLATTNER_COST)) || 0,
                [NCRDataConstants.REINSTALLATION_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.REINSTALLATION_BLATTNER_COST)) || 0,
                [NCRDataConstants.OTHER_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.OTHER_BLATTNER_COST)) || 0
            },
            nonBlattnerCosts: {
                [NCRDataConstants.INVESTIGATION_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.INVESTIGATION_NON_BLATTNER_COST)) || 0,
                [NCRDataConstants.WORK_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.WORK_NON_BLATTNER_COST)) || 0,
                [NCRDataConstants.ADDITIONAL_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.ADDITIONAL_NON_BLATTNER_COST)) || 0,
                [NCRDataConstants.REMOVAL_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.REMOVAL_NON_BLATTNER_COST)) || 0,
                [NCRDataConstants.REINSTALLATION_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.REINSTALLATION_NON_BLATTNER_COST)) || 0,
                [NCRDataConstants.OTHER_NON_BLATTNER_COST]: parseFloat(this.getScreenFieldValue(NCRDataConstants.OTHER_NON_BLATTNER_COST)) || 0
            },
            blattnerTotalCosts: parseFloat(this.getScreenFieldValue(NCRDataConstants.BLATTNER_TOTAL_COST_INPUT)) || 0,
            nonBlattnerTotalCosts: parseFloat(this.getScreenFieldValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST_INPUT)) || 0
        }
    }

    setBlattnerCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = parseFloat(event.target.value) || 0;
        const roundedValue = parseFloat(FormatNumber(value, 2));
        const costDictionary = this.state.blattnerCosts;
        costDictionary[name] = roundedValue;

        var sum = 0;
        for (let key in costDictionary) {
            sum += costDictionary[key];
        }

        this.setState({ 
            blattnerCosts: costDictionary,
            blattnerTotalCosts: sum
        });
    }

    setNonBlattnerCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = parseFloat(event.target.value) || 0;
        const roundedValue = parseFloat(FormatNumber(value, 2));
        const costDictionary = this.state.nonBlattnerCosts;
        costDictionary[name] = roundedValue;

        var sum = 0;
        for (let key in costDictionary) {
            sum += costDictionary[key];
        }

        this.setState({ 
            nonBlattnerCosts: costDictionary,
            nonBlattnerTotalCosts: sum
        });
    }

    showExplanation(blattnerCost: string, nonBlattnerCost: string)
    {
        return (this.state.blattnerCosts[blattnerCost] > 0 || this.state.nonBlattnerCosts[nonBlattnerCost] > 0)
    }

    totalCombinedCosts() {
        return FormatNumber((this.state.blattnerTotalCosts + this.state.nonBlattnerTotalCosts), 2);
    }

    getScreenFieldValue(dataName: string) {
        return GetScreenFieldValue(this.props.task.screen.screenFields, dataName);
    }
    
    costComponent(headerTitle: string, headerHintText: string, blattnerCostDataName: string, nonBlattnerCostDataName: string, explanationDataName: string) {
        return (
            <div>
                <Header title={headerTitle} hintText={headerHintText} headerColor="white" textColor="black" />
                <NumberField dataName={blattnerCostDataName} title={NCRDataConstants.BLATTNER_COST_TITLE} value={this.state.blattnerCosts[blattnerCostDataName].toString()} decimals={2} onchange={this.setBlattnerCost} required={true} />
                <NumberField dataName={nonBlattnerCostDataName} title={NCRDataConstants.NON_BLATTNER_COST_TITLE} value={this.state.nonBlattnerCosts[nonBlattnerCostDataName].toString()} decimals={2} onchange={this.setNonBlattnerCost} required={true} />
                { this.showExplanation(blattnerCostDataName, nonBlattnerCostDataName) ? <TextField dataName={explanationDataName} title={NCRDataConstants.EXPLANATION_COST_TITLE} value={this.getScreenFieldValue(explanationDataName)} required={true} /> : "" }
            </div>
        )
    }

    totalCostComponent() {
        return (
            <div>
                <Header title={NCRDataConstants.TOTAL_COST_TITLE} headerColor="white" textColor="black" />
                <NumberField dataName={NCRDataConstants.BLATTNER_TOTAL_COST_INPUT} title={NCRDataConstants.BLATTNER_TOTAL_COST_TITLE} value={this.state.blattnerTotalCosts.toString()} decimals={2} readonly={true} />
                <HiddenField dataName={NCRDataConstants.BLATTNER_TOTAL_COST} value={FormatNumber(this.state.blattnerTotalCosts > 0 ? this.state.blattnerTotalCosts : parseFloat(this.getScreenFieldValue(NCRDataConstants.BLATTNER_TOTAL_COST)), 2)} />
                <NumberField dataName={NCRDataConstants.NON_BLATTNER_TOTAL_COST_INPUT} title={NCRDataConstants.NON_BLATTNER_TOTAL_COST_TITLE} value={this.state.nonBlattnerTotalCosts.toString()} decimals={2} readonly={true} />
                <HiddenField dataName={NCRDataConstants.NON_BLATTNER_TOTAL_COST} value={FormatNumber(this.state.nonBlattnerTotalCosts > 0 ? this.state.nonBlattnerTotalCosts : parseFloat(this.getScreenFieldValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST)), 2)} />
                <NumberField dataName={NCRDataConstants.COMBINED_TOTAL_COST_INPUT} title={NCRDataConstants.COMBINED_TOTAL_COST_TITLE} value={this.totalCombinedCosts()} decimals={2} readonly={true} />
                <HiddenField dataName={NCRDataConstants.COMBINED_TOTAL_COST} value={parseFloat(this.totalCombinedCosts()) > 0 ? this.totalCombinedCosts() : FormatNumber(parseFloat(this.getScreenFieldValue(NCRDataConstants.COMBINED_TOTAL_COST)), 2)} />
            </div>
        )
    }

    // function to get media fields
    getMediaFiles() {
        const fileNamesFields = this.props.task.screen.screenFields.filter(x => x.dataName.includes(NCRDataConstants.ADDITIONAL_PHOTO_FILE_NAME));
        const fileFields = this.props.task.screen.screenFields.filter(x => x.dataName.includes(NCRDataConstants.ADDITIONAL_PHOTO_URL));
        
        return (
            fileNamesFields.map((field, i) => {
                return (
                    <div key={i}>
                        <TextField dataName={field.dataName} title={field.title} value={field.value} hintText={field.hintText} readonly={true} />
                        <FileField title={fileFields[i].title} value={fileFields[i].value} hintText={fileFields[i].hintText} />
                    </div>
                )
            })
        )
    }

    render () {
        return (
            <div>
                <HiddenField dataName={NCRDataConstants.COMPLETE_BY} value={this.props.task.tier === 2 ? this.context.accounts[0].name : this.getScreenFieldValue(NCRDataConstants.COMPLETE_BY)} />
                <FileUploadField dataName={NCRDataConstants.ADDITIONAL_PHOTO_URL} tableDataName={NCRDataConstants.ADDITIONAL_PHOTO_TABLE} filenameDataName={NCRDataConstants.ADDITIONAL_PHOTO_FILE_NAME} title={NCRDataConstants.PHOTO_TITLE} multiple={true} 
                    fileadd={this.props.fileAddHandler} fileremove={this.props.fileRemoveHandler} filesIn={this.props.files} />
                {this.getMediaFiles()}
            </div>
        )
    }
}
