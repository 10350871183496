import http from "../http-common";
import ITestGroup from "../types/TestGroup"

class TestService {
  insertGroup(data: ITestGroup) {
    return http.post(`/insertGroup`, data, { headers: { 'Content-Type': 'application/json' }});
  }

  getGroup(email: string) {
    return http.get<ITestGroup[]>(`/getGroup?userEmail=${email}`);
  }
}

const testServiceInstance = new TestService();

export default testServiceInstance;