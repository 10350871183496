import React from 'react';
import { toast } from 'react-toastify';

export const clearToasts = () => {toast.dismiss()};

export const infoToast = (msg: React.ReactNode | string) => {
  toast.info(msg, {
      position: 'top-center',
      pauseOnHover: false,
      hideProgressBar: true,
      autoClose: 3999,
      closeOnClick: true,
      theme: 'colored',
      icon: false,
  });
};

export const successToast = (msg: React.ReactNode | string, autoClose: boolean = true) => {
  toast.success(msg, {
      position: 'top-center',
      pauseOnHover: false,
      hideProgressBar: true,
      autoClose: autoClose ? 3999 : false,
      closeOnClick: true,
      theme: 'colored',
      icon: false,
  });
};

// more work should be done here!
export const warningToast = (msg: React.ReactNode | string, autoClose: boolean = false) => {
    toast.warn(msg, {
        draggable: true,
        position: 'top-center',
        pauseOnHover: true,
        hideProgressBar: true,
        autoClose: autoClose ? 2999 : false,
        closeOnClick: true,
        theme: 'colored',
        icon: false,
  });
};

export type MessageOptions = {
    autoClose: number | false | undefined,
    pauseOnHover: boolean | undefined,
    customID: string | undefined,
}

export const errorToast = (
  msg: React.ReactNode | string,
  options: MessageOptions = {
    autoClose: false,
    pauseOnHover: false,
    customID: undefined,
  }
) => {
    toast.error(msg , {
        position: 'top-center',
        pauseOnHover: options.pauseOnHover,
        hideProgressBar: !options.pauseOnHover,
        autoClose: options.autoClose,
        closeOnClick: true,
        theme: 'colored',
        toastId: options.customID ?? Date.now(),
        icon: false,
    });
};