import http from "../http-common";
import { DataSource } from "../types/Data/DataSource";
import DataSourceQueryList from "../types/Data/DataSourceQueryList";
import DataSourcesResponse from '../types/Data/DataSourcesResponse';
import IEnvironment from "../types/Data/Environment";
import FormEntry from "../types/FormEntry/FormEntry";

class DataService {
  searchDataSourceData(dataSource: string, columnIndex: string, searchString: string) {
    return http.get<string[]>(`/searchdatasourcedata?dsExternalID=${dataSource}&columnIndex=${columnIndex}&q=${searchString}`);
  }

  searchDataSourceDataWithQuery(dataSource: string, columnIndex: number, query: DataSourceQueryList) {
    return http.post<string[]>(`/searchDataSourceDataWithQuery?dsExternalID=${dataSource}&columnIndex=${columnIndex}`, query, { headers: { 'Content-Type': 'application/json' }});
  }

  getUserDataSourceHeaders(userEmail: string, companyID: string, externalID?: string) {
    let url = `email=${userEmail}&companyID=${companyID}`
    if(externalID) {
      url += `&dsExternalID=${externalID}`
    }
    return http.get<DataSourcesResponse>(`/getUserDataSourceHeaders?${url}`);
  }

  getAllHeaders(companyID: string) {
    return http.get<DataSourcesResponse>(`/getAllSourceHeaders?companyID=${companyID}`);
  }

  getDataSource(companyID: string, externalID: string) {
    let url = `companyID=${companyID}&dsExternalID=${externalID}`;
    return http.get<DataSource>(`/getDataSourceValues?${url}`);
  }

  setDataSource(dataSource: DataSource) {
    return http.post('/setDataSourceValues', dataSource, { headers: { 'Content-Type': 'application/json' }});
  }

  getFormEntry(companyID: string, formEntryID: string) {
    return http.get<FormEntry>(`/getFormEntry?companyID=${companyID}&formEntryID=${formEntryID}`);
  }

  clearDataSourceCache(companyID: string, externalID: string) {
    let url = `companyID=${companyID}&dsExternalID=${externalID}`;
    return http.post(`/clearDataSourceCache?${url}`);
  }

  getEnvironmentIds() {
      return http.get<IEnvironment[]>(`/getAllFormsOnFireProjectData`);
  }
}

const dataServiceInstance = new DataService();

export default dataServiceInstance;