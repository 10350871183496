export enum GroupList {
    SC_Senior_Managers = "SC Senior Managers",
    SC_Manager_Sourcing = "SC Manager II - Sourcing",
    SC_Manager_Quality = "SC Manager II - Quality",
    SC_Engineer_Quality = "SC Engineer - Quality",
    PC_Project_Engineers = "PC Project Engineers",
    PC_Project_Engineering = "PC Project Engineering",
    Civil_Engineer = "Civil Engineer",
    Project_Civil_Engineer = "Project Civil Engineer",
    Senior_Civil_Engineer = "Senior Civil Engineer",
    Quality_Data_Analyst = "Quality Data Analyst",
    Quality_System_Analysts = "Quality System Analysts",
    Quality_System_Assistants = "Quality System Assistant",
    Quality_Assistants = " Quality Assistants",
    Quality_Systems_Manager = "Quality Systems Manager",
    Project_Manager = "Project Manager",
    Site_Manager = "Site Manager",
    Quality_Supt = "Quality Superintendent",
    Director_Construction_Wind = "Directors of Construction - Wind",
    Director_Construction_Solar = "Directors of Construction - Solar"
}