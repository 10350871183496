export enum RoleGroup {
    "None" = 0, // Leave first as default
    "DOT Coordinators" = 1,
    "Civil FE" = 2,
    "Commissioning Foreperson" = 3,
    "Electrical FE" = 4,
    "Installation FE" = 5,
    "Substation FE" = 6,
    "Transmission Line FE" = 7,
    "Collections FE" = 8,
    "Foundations FE" = 9,
    "Repower FE" = 10,
    "Electrical BESS FE" = 11,
    "Foundations BESS FE" = 12,
    "Foundations BESS Superintendent" = 13,
    "Electrical BESS Superintendent" = 14,
    "Repower Superintendent" = 15,
    "Transmission Line Superintendent" = 16,
    "Substation Superintendent" = 17,
    "Installation Superintendent" = 18,
    "Foundations Superintendent" = 19,
    "Collections Superintendent" = 20,
    "Civil Superintendent" = 21,
    "Electrical Superintendent" = 22,
    "Switchyard FE" = 23,
    "Switchyard Superintendent" = 24,
    "Overhead Collections FE" = 25,
    "Overhead Collections Superintendent" = 26
}