import React, { useEffect, useState, useCallback } from 'react';
import { Spinner } from 'react-bootstrap';
import { DataSourceStatus } from '../../enums/DataSourceStatus';
import dataSourceService from '../../services/DataSourceService';
import DataSourceActivityDetail from '../../types/DataSource/DataSourceActivityDetail';
import DataSourceEmployeeInfo from '../../types/Employee/DataSourceEmployeeInfo';
import DataSourceMessage from './DataSourceMessage';
import { infoToast, successToast, clearToasts } from '../Toasty';

import '../../styles/Common.css';

const ONE_MINUTE_OF_MILLISECONDS = (60 * 1000);
const ONE_HOUR_OF_MILLISECONDS = ( 60 * ONE_MINUTE_OF_MILLISECONDS);
const ONE_DAY_OF_MILLISECONDS = (24 * ONE_HOUR_OF_MILLISECONDS);

const getTimeRemaining = (checkoutTime: number) => ONE_DAY_OF_MILLISECONDS - (Date.now() - checkoutTime);

const stringifyTimeLeft = (timeLeft: number) => {
    let rollingTime = timeLeft;
    const hours = Math.floor(rollingTime / ONE_HOUR_OF_MILLISECONDS);
    rollingTime -= (hours * ONE_HOUR_OF_MILLISECONDS);
    const minutes = Math.floor(rollingTime / ONE_MINUTE_OF_MILLISECONDS);
    rollingTime -= (minutes * ONE_MINUTE_OF_MILLISECONDS);
    const seconds = Math.floor(rollingTime / 1000);
    return (`${('0' + hours).slice(-2)}h:${('0' + minutes).slice(-2)}m:${('0' + seconds).slice(-2)}s`);
};

interface ClaimButtonProps {
    user: DataSourceEmployeeInfo,
    dataSourceName: string,
    environmentId: string,
    externalId:  string,
    onClick?: (claimStatus: DataSourceStatus) => void | undefined,
    reportInitialStatus: (claimStatus: DataSourceStatus) => void,
};

const ClaimButton = ({ user, dataSourceName,  environmentId, externalId, onClick, reportInitialStatus}: ClaimButtonProps) => {
    const [claimStatus, setClaimStatus] = useState<DataSourceStatus>(DataSourceStatus.Unclaimed);
    const [processingClaim, setProcessingClaim] = useState<boolean>(false);

    const getDataSourceActivity = useCallback(async () => {
        try {
            const { data } = await dataSourceService.getDataSourceActivity(environmentId, externalId);
            return data;
        } catch (error: any) {
            throw new Error(`An Error Occurred while trying to get the Data Source Claim Status ${error.message}`);
        }
    }, [environmentId, externalId]);

    const getStatusFromActivity = useCallback((data: DataSourceActivityDetail) => {
        let status: DataSourceStatus;

        if ((data.dateCheckedIn !== 0) || (data.dateCheckedOut === 0)) {
            status = DataSourceStatus.Unclaimed
        } else if ((data.dateCheckedIn === 0) && (data.employeeName !== user.employeeName)) {
            status = DataSourceStatus.ClaimedByOther;
        } else if ((data.dateCheckedIn === 0) && (data.employeeName === user.employeeName)) {
            status = DataSourceStatus.ClaimedByUser;
        } else {
            setClaimStatus(DataSourceStatus.Error);
            throw new Error(`something is wrong with the claiming system: ${data}`);
        }
        setClaimStatus(status);
        return status;
    }, [setClaimStatus, user]);

    useEffect(() => {
        if (!environmentId || !externalId) {
            return;
        }
        setProcessingClaim(true);
        const getActivity = async() => {
            try {
                const activity = await getDataSourceActivity();
                const status = getStatusFromActivity(activity);
                if (status) {
                    setClaimStatus(status);
                }
                if (status === DataSourceStatus.ClaimedByUser) {
                    const time = new Date();
                    time.setTime(activity.dateCheckedOut + ONE_DAY_OF_MILLISECONDS);
                    successToast(DataSourceMessage(dataSourceName,
                        <div>
                            <i className='toast-message-subtext'>
                                Claim expires on <b>{time.toLocaleDateString()}</b> at <b>{time.toLocaleTimeString()}</b>
                            </i>
                        </div>
                    ));
                }
                setClaimStatus(status);
                reportInitialStatus(status);
            } catch (error) {
                console.log(`Error getting claim status for ${externalId}: ${error}`)
            } finally {
                setProcessingClaim(false);
            };
        };
        getActivity();
        // omitting onClick to prevent issues when button component is reloading because of multiple page renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDataSourceActivity, getStatusFromActivity, environmentId, externalId, dataSourceName]);

    const buttonClick = async() => {
        setProcessingClaim(true);
        try {
            // double check the status of the claim (make sure no one has grabbed it in the mean time)
            const activity = await getDataSourceActivity();
            const currentStatus = getStatusFromActivity(activity);
            let newStatus: DataSourceStatus = DataSourceStatus.Error;
            switch (currentStatus) {
                case DataSourceStatus.Unclaimed:
                    if (!activity) {
                        break;
                    }
                    // this handles the case where we had tried to claim it, but set the status to "claimed" by another
                    //   and now it is unclaimed
                    if (claimStatus !== currentStatus) {
                        newStatus = DataSourceStatus.Unclaimed;
                        successToast(DataSourceMessage(dataSourceName,
                            <div className='text-center'>
                                Is now Available!
                            </div>
                        ));
                        break;
                    }
                    // this is to handle the case that it was already unclaimed so we can just grab it.
                    const claimAD: DataSourceActivityDetail = {
                        dateCheckedOut: new Date().getTime(),
                        dateCheckedIn: 0,
                        employeeID: user.employeeID.toString(),
                        employeeName: user.employeeName,
                    };

                    const { status, statusText } = await dataSourceService.setDataSourceActivity(environmentId, externalId, claimAD);
                    
                    if (status !== 200) {
                        throw new Error(`Attempting to get data source activity responded with [${status}]: ${statusText}`)
                    }

                    const time = new Date(Date.now() + ONE_DAY_OF_MILLISECONDS);
                    successToast(DataSourceMessage(dataSourceName,
                        <div>
                            <div>
                                You have claimed the Data Source.
                            </div>
                            <div>
                                After your claim expires it will be available for others to claim and your unsaved work will be lost!
                            </div>
                            <i className='toast-message-subtext'>
                                Expires on <b>{time.toLocaleDateString()}</b> at <b>{time.toLocaleTimeString()}</b>
                            </i>
                        </div>
                    ), false);
                    newStatus = DataSourceStatus.ClaimedByUser;
                    break;

                case DataSourceStatus.ClaimedByUser:
                    // unclaim it
                    if (!activity) {
                        break;
                    }
                    const unclaimAD = activity;
                    unclaimAD.dateCheckedIn = new Date().getTime();
                    await dataSourceService.setDataSourceActivity(environmentId, externalId, unclaimAD);
                    newStatus = DataSourceStatus.Unclaimed;
                    clearToasts();
                    break;

                case DataSourceStatus.ClaimedByOther:
                    // this will make sure the claim status is up to date
                    // (it may not be if someone else claims the DS after the user loads the page)
                    const checkoutTime = new Date(activity.dateCheckedOut);
                    const timeRemaining = getTimeRemaining(activity.dateCheckedOut);
                    if (timeRemaining < 0) {
                        // user claim has expired but it looks like it hasn't
                        //   probably a latency issue where we are on the edge of the expiration time (sub-seconds)
                        //   hopefully the user will just hit the button again, but this should be handled better
                        break;
                    }
                    infoToast(DataSourceMessage(dataSourceName,
                        <div>
                            <div>
                                Claimed by: {activity.employeeName}
                            </div>
                            <div>
                                On: {checkoutTime.toLocaleDateString()} at {checkoutTime.toLocaleTimeString()}
                            </div>
                            <div className=''>
                                <i>
                                    {stringifyTimeLeft(timeRemaining)} - left on their claim
                                </i>
                            </div>
                        </div>
                    ))
                    newStatus = DataSourceStatus.ClaimedByOther;
                    break;
            }
            setClaimStatus(newStatus);
            if (onClick) {
                onClick(newStatus ?? DataSourceStatus.Error);
            }
        } catch (error) {
            console.log('Error claiming the Data Source', error);
        } finally {
            setProcessingClaim(false);
        };
    };

    const getStatusString = (status: DataSourceStatus) => {
        switch(status) {
            case DataSourceStatus.Unclaimed:
                return 'Claim';

            case DataSourceStatus.ClaimedByUser:
                return 'Unclaim';

            case DataSourceStatus.ClaimedByOther:
                return 'Claimed';

            case DataSourceStatus.Error:
                return 'Error';
        }
    };

    const getButtonColoring = (status: DataSourceStatus) => {
        switch(status) {
            case DataSourceStatus.Unclaimed:
                return 'btn-blattnerblue';

            case DataSourceStatus.ClaimedByUser:
                return 'btn-outline-success';

            case DataSourceStatus.ClaimedByOther:
                return 'btn-outline-dark';

            case DataSourceStatus.Error:
                return 'btn-outline-danger';
        }
    };

    return (
        <button
            type='button'
            className={`btn data-source-btn text-center ${getButtonColoring(claimStatus)}`}
            onClick={buttonClick}
        >
            {processingClaim && <Spinner animation='border' size='sm' role='status' aria-hidden='true' />}
            {!processingClaim && (getStatusString(claimStatus))}
        </button>
    );
};

export default ClaimButton;