export const enum FieldType {
    None = 0, // Leave first as default
    Button = 1,
    ChoicesField = 2,
    DateField = 3,
    Header = 4,
    Hidden = 7,
    NumberField = 5,
    TextField = 6,
    Media = 8,
    Page = 9,
    File = 10
}