import Editable from '../../components/Icons/Editable';
import EyeSlash from '../../components/Icons/EyeSlash';
import Uneditable from '../../components/Icons/Uneditable';

const DataEditorHelp = () => (
    <div>
        <h5>Claiming Rules</h5>
        <ul>
            <li>
                When a Data Source is claimed, others cannot edit it for up to 24 hours.<br/>
                <i>
                    <b>After 24 hours:</b> The Data Source will be automatically unclaimed and any un-saved changes will be lost.
                </i>
            </li>
            <li>Any Data Source changes made directly in Blattner Mobile backend will not be visible here until 1 hour has passed.</li>
        </ul>
        <h5>Editing</h5>
        <ul>
            <li><b>Important!<i> Refreshing this page will erase any changes made since Claiming or the last Save, whichever occurred most recently.</i></b></li>
            <li><b>Double click</b> on an editable cell to modify the content.  Then press enter (or click off the cell) to stop editing.</li>
            <li>
                <b>Right click</b> on row to perform row actions. Insert above; insert below; clone; and delete; are the available actions.
                <br /><i>(Not all row actions are allowed on all rows.)</i>
            </li>
            <li>Saved changes are available to the Field imediately after they refresh their data.</li>
        </ul>
        <h5>Importing / Exporting</h5>
        <ul>
            <li>
                <b>Exporting Excel Files</b> can be done, by those who <i><b>have view permissions</b></i> for that Data Source
            </li>
            <li>
                <b>Importing Excel Files</b> to update the Data source can be done, if you <i><b>have edit permissions</b></i> for that Data Source.
                <br />The rules governing what can be entered still apply and will show in red if they don't meet the criteria.
                <br /><i><b>Note: While we can catch some details to help prevent erroneous data input, the risk is on the importer</b></i>
                <br /><i><b>to verify that the data is correct and will not break any forms</b></i>
            </li>
        </ul>
        <h5>Display</h5>
        <ul>
            <li>The border of a cell will change if you can edit the cell's data.</li>
            <li>The cell background will change to <i>green</i> when the cell's data has been updated but not yet saved.</li>
            <li>
                Some cells will have data validation associated with it (i.e. if it needs to be a number, or it cannot be blank).
                <br />If the validation criteria <b>has not</b> been met, the cell background will change to <i>red</i>.
            </li>
            <li>If a cell is empty/blank and <b>editable</b> it will show <Editable /> icon</li>
            <li>If a cell is empty/blank and <b>uneditable</b> it will show <Uneditable /> icon</li>
            <li>
                Users will not see entire rows or columns that are hidden.  However, sometimes only a part of a row or column is hidden.
                <br />If a cell is <b>hidden</b> from the current user it will show <EyeSlash /> icon
            </li>
        </ul>
    </div>
)

export default DataEditorHelp;