import React from'react';

type HeaderProps = {
    title: string,
    headerColor: string,
    textColor: string,
    hintText?: string
}

const Header = ({ title, hintText, headerColor, textColor }: HeaderProps) => {

    const headerColorStyle = {
        backgroundColor: headerColor,
    };

    const textColorSyle = {
        color: textColor,
    };

    return (
        <div className="HeaderDiv mt-2 mb-2" style={headerColorStyle}>
            <h4 className="header" style={textColorSyle} >{title}
            <p className="HintText" style={textColorSyle}>{hintText}</p>
            </h4>
        </div>
    );
}

export default Header