import { Component } from "react";

import ADSearchField from "./ADSearchField";
import DateField from "./DateField";
import FileField from "./FileField";
import FileUploadField from "./FileUploadField";
import Header from "./Header";
import SelectField from './SelectField';
import TextArea from "./TextArea";
import TextField from './TextField';

import { errorToast } from '../components/Toasty';
import * as NCRDataConstants from "../constants/NCRDataConstants";
import { Stage } from '../enums/Stage';
import GetScreenFieldValue from "../functions/GetScreenFieldValue";
import TaskService from "../services/TaskService";
import Task from "../types/Task/Task";
import MultiSelectField, { multiSelectOption } from "./MultiSelectField";

type Props = {
    task: Task,
    files: File[],
    fileAddHandler: (e: any) => void,
    fileRemoveHandler: (e: any) => void,
    stageChange: (e: any) => void,
    onError?: (error: boolean) => void
};

type State = {
    closeNCRStep1Value: string,
    closeNCRStep2Value: string,
    targetImplementationDate: string, 
    targetChanges: string,
    assignActionItem: string,
    assignImplementation: string,
    noActionReasons: string[],
    closureStatuses: string[],
    isNcrClosureStatusError: boolean
};

export default class QualityExpediting extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { 
            closeNCRStep1Value: this.getScreenFieldValue(NCRDataConstants.CLOSE_NCR_STEP_1),
            closeNCRStep2Value: this.getScreenFieldValue(NCRDataConstants.CLOSE_NCR_STEP_2),
            targetImplementationDate: this.getScreenFieldValue(NCRDataConstants.TARGET_IMPLEMENTATION_DATE),
            targetChanges: this.getScreenFieldValue(NCRDataConstants.IMPLEMENT_CHANGES),
            assignActionItem: this.getScreenFieldValue(NCRDataConstants.ASSIGN_TO_CHOICE),
            assignImplementation: this.getScreenFieldValue(NCRDataConstants.ASSIGN_TO_CHOICE_IMPLEMENTATION),
            noActionReasons: [],
            closureStatuses: [],
            isNcrClosureStatusError: false
        }
    }

    // function get No Action Reasons
    async getNoActionReasons() {
        await TaskService.getDataSourceData(this.props.task.id.toString(), "DATA_DEFINITIONS", "9")
            .then((response) => {
                this.setState({                    
                    noActionReasons: response.data
                })
            })
    }

    // function get Closure Statuses
    async getClosureStatuses() {
        await TaskService.getDataSourceData(this.props.task.id.toString(), "DATA_DEFINITIONS", "10")
            .then((response) => {
                this.setState({                    
                    closureStatuses: response.data
                })
            })
    }

    // componentDidMount method is called after the component is rendered. 
    componentDidMount() {
        this.getNoActionReasons();
        this.getClosureStatuses();
    }

    // function to track Close NCR Step1 value.  Passed into child component to change parent state
    handleCloseNCRStep1Change = (event: any) => {
        const newValue = event.target.value;
        this.setState({ 
            closeNCRStep1Value: newValue,
        });
    }

    // function to track Close NCR Step2 value.  Passed into child component to change parent state
    handleCloseNCRStep2Change = (event: any) => {
        const newValue = event.target.value;
        this.setState({ 
            closeNCRStep2Value: newValue 
        });
    }

    // function to track Target Implementation Date value.  Passed into child component to change parent state
    handleTargetImplementationDateChange = (input: string) => {
        this.setState({ 
            targetImplementationDate: input 
        });
    }

    // function to track Target Changes value.  Passed into child component to change parent state
    handleTargetChangesChange = (event: any) => {
        const newValue = event.target.value;
        this.setState({
            targetChanges: newValue
        });
    }

    // function to track Assign Action value.  Passed into child component to change parent state
    handleAssignActionChange = (event: any) => {
        const newValue = event.target.value;
        this.setState({
            assignActionItem: newValue
        });

        if (newValue === NCRDataConstants.ASSIGN_MYSELF) {
            this.props.stageChange(Stage.CorporateAnalysis);
        } else {
            this.props.stageChange(Stage.QualitExpediting);
        }        
    }

    // function to track Assign Implementation value.  Passed into child component to change parent state
    handleAssignImplementationChange = (event: any) => {
        const newValue = event.target.value;
        this.setState({
            assignImplementation: newValue,
        });
    }

    // function to get screen field value from list of screen fields using dataName
    getScreenFieldValue(dataName: string) {
        return GetScreenFieldValue(this.props.task.screen.screenFields, dataName);
    }

    // function to check if stage is quality expediting
    checkIfQualityExpediting() {
        return this.props.task.stage === Stage.QualitExpediting;
    }

    // function to check if stage is either quality expiditing or corporate analysis
    checkIfCorporateAnalysis() {
        return (this.props.task.stage === Stage.QualitExpediting || this.props.task.stage === Stage.CorporateAnalysis);
    }

    // function to get media fields
    getMediaFiles() {
        const fileNamesFields = this.props.task.screen.screenFields.filter(x => x.dataName.includes(NCRDataConstants.ACTION_ITEM_PHOTO_FILE_NAME));
        const fileFields = this.props.task.screen.screenFields.filter(x => x.dataName.includes(NCRDataConstants.ACTION_ITEM_PHOTO_URL));
        
        return (
            fileNamesFields.map((field, i) => {
                return (
                    <div key={i}>
                        <TextField dataName={field.dataName} title={field.title} value={field.value} hintText={field.hintText} readonly={true} />
                        <FileField title={fileFields[i].title} value={fileFields[i].value} hintText={fileFields[i].hintText} />
                    </div>
                )
            })
        )
    }

    // function to return quality expediting fields
    qualityExpedtingFields() {
        var value = this.state.closeNCRStep1Value;
        if (value !== "") {
            if (value === NCRDataConstants.WITHOUT_ACTION_ITEMS) {
                return (
                    <div>
                        <SelectField dataName={NCRDataConstants.REASON_NO_ACTION} title={NCRDataConstants.REASON_NO_ACTION_TITLE} value={this.getScreenFieldValue(NCRDataConstants.REASON_NO_ACTION)} readonly={!this.checkIfQualityExpediting()} required={this.checkIfQualityExpediting()}
                            options={this.state.noActionReasons.map((reason) => ({value: reason}))} />
                        <TextField dataName={NCRDataConstants.NO_ACTION_COMMENTS} title={NCRDataConstants.NO_ACTION_COMMENTS_TITLE} value={this.getScreenFieldValue(NCRDataConstants.NO_ACTION_COMMENTS)} readonly={!this.checkIfQualityExpediting()} required={false} />
                    </div>
                )
            } else {            
                return (
                    <div>
                        {this.checkIfQualityExpediting() ? <SelectField dataName={NCRDataConstants.ASSIGN_TO_CHOICE} title={NCRDataConstants.ASSIGN_TO_CHOICE_TITLE} value={this.state.assignActionItem} 
                            options={[ { value: NCRDataConstants.ASSIGN_MYSELF }, { value: NCRDataConstants.ASSIGN_ANOTHER_PERSON }]} readonly={!this.checkIfQualityExpediting()} required={this.checkIfQualityExpediting()} onchange={this.handleAssignActionChange} /> : "" }
                        {this.checkIfQualityExpediting() && this.state.assignActionItem === NCRDataConstants.ASSIGN_MYSELF ? 
                            this.corporateAnalysis() : "" }
                        {this.checkIfQualityExpediting() && this.state.assignActionItem === NCRDataConstants.ASSIGN_ANOTHER_PERSON ? 
                            <ADSearchField dataName={NCRDataConstants.ASSIGN_TO} title={NCRDataConstants.ASSIGN_TO_TITLE} disabled={!this.checkIfQualityExpediting()} required={true} /> : "" }
                    </div> 
                )
            }
        }
    }

    // funciton to return corporate analysis fields
    corporateAnalysis() {
        return (
            <div>
                <DateField dataName={NCRDataConstants.TARGET_IMPLEMENTATION_DATE} title={NCRDataConstants.TARGET_IMPLEMENTATION_DATE_TITLE} value={this.state.targetImplementationDate} readonly={!this.checkIfCorporateAnalysis()} 
                    required={false} defaultCurrentDate={true} restrictPreviousDate={true} onchange={this.handleTargetImplementationDateChange} />
                {this.checkIfCorporateAnalysis() ? <FileUploadField dataName={NCRDataConstants.ACTION_ITEM_PHOTO_URL} tableDataName={NCRDataConstants.ACTION_ITEM_PHOTO_TABLE} filenameDataName={NCRDataConstants.ACTION_ITEM_PHOTO_FILE_NAME} title={NCRDataConstants.PHOTO_TITLE} multiple={true} 
                    fileadd={this.props.fileAddHandler} fileremove={this.props.fileRemoveHandler} filesIn={this.props.files} />
                    : null}
                {this.getMediaFiles()}
                <SelectField dataName={NCRDataConstants.CLOSE_NCR_STEP_2} title={NCRDataConstants.CLOSE_NCR_STEP_2_TITLE}
                    options={[ { value: NCRDataConstants.WITH_ACTION_ITEMS }, { value: NCRDataConstants.WITHOUT_ACTION_ITEMS }]} 
                    value={this.state.closeNCRStep2Value} readonly={!this.checkIfCorporateAnalysis()} required={true} onchange={this.handleCloseNCRStep2Change} />
                {this.corporateActionItemFields()}
            </div>
        )
    }

    // function to return corporate action item fields
    corporateActionItemFields() {
        var value = this.state.closeNCRStep2Value;
        if (value !== "") {
            if (value === NCRDataConstants.WITHOUT_ACTION_ITEMS) {
                return (
                    <div>
                        <SelectField dataName={NCRDataConstants.REASON_NO_ACTION} title={NCRDataConstants.REASON_NO_ACTION_TITLE} value={this.getScreenFieldValue(NCRDataConstants.REASON_NO_ACTION)} readonly={false} required={true}
                            options={this.state.noActionReasons.map((reason) => ({value: reason}))} />
                        <TextField dataName={NCRDataConstants.NO_ACTION_COMMENTS} title={NCRDataConstants.NO_ACTION_COMMENTS_TITLE} value={this.getScreenFieldValue(NCRDataConstants.NO_ACTION_COMMENTS)} readonly={false} required={false} />
                    </div>
                )
            } else {
                return (
                    <div>
                        <TextField dataName={NCRDataConstants.IMPLEMENT_CHANGES} title={NCRDataConstants.IMPLEMENT_CHANGES_TITLE} value={this.state.targetChanges} readonly={!this.checkIfCorporateAnalysis()} required={true}
                            onchange={this.handleTargetChangesChange} />
                        {this.checkIfCorporateAnalysis() ? <SelectField dataName={NCRDataConstants.ASSIGN_TO_CHOICE_IMPLEMENTATION} title={NCRDataConstants.ASSIGN_TO_CHOICE_IMPLEMENTATION_TITLE} value={this.state.assignImplementation} 
                            options={[ { value: NCRDataConstants.ASSIGN_MYSELF }, { value: NCRDataConstants.ASSIGN_ANOTHER_PERSON }]} readonly={false} required={this.checkIfCorporateAnalysis()} onchange={this.handleAssignImplementationChange} /> : ""}
                        {this.checkIfCorporateAnalysis() && this.state.assignImplementation === NCRDataConstants.ASSIGN_MYSELF ?
                            this.implementation() : ""}
                        {this.checkIfCorporateAnalysis() && this.state.assignImplementation === NCRDataConstants.ASSIGN_ANOTHER_PERSON ? 
                            <ADSearchField dataName={NCRDataConstants.ASSIGN_TO_IMPLEMENTATION} title={NCRDataConstants.ASSIGN_TO_TITLE} disabled={!this.checkIfCorporateAnalysis()} required={true} /> 
                            : ""}
                        
                    </div>
                )
            }
        }
    }

    onMultiSelectChange (data: multiSelectOption[]) {
        const errorMessage = () => (
            <div className='text-center'>
                <div><b>NCR Closure Status</b></div>
                <div>cannot have 'No Change'</div>
                <div>selected with other values.  Please update your selection accordingly.</div>
            </div>
        );

        const selectedValues = data.map(x => x.value);
        const errorPresent = selectedValues.some(x => x === 'No Change') && selectedValues.length > 1;
        this.setState({
            isNcrClosureStatusError: errorPresent,
        })
        if (this.props.onError) {
            this.props.onError(errorPresent)
        }
        if (errorPresent) {
            errorToast(errorMessage(), { autoClose: 3000, pauseOnHover: true, customID: '???'});
        }
    }

    // function to return implementaton fields
    implementation() {
        var actualDate = this.getScreenFieldValue(NCRDataConstants.ACTUAL_IMPLEMENTATION_DATE);
        var actualChanges = this.getScreenFieldValue(NCRDataConstants.ACUTAL_IMPLEMENTATION_CHANGES);

        const multiSelectOptions = this.state.closureStatuses.map((status: string) => ({ label: status, value: status }));
        const specialOptions = this.state.closureStatuses.slice(0, -1).map((status: string) => ({ label: status, value: status }));

        return (
            <div>
                <DateField
                    dataName={NCRDataConstants.ACTUAL_IMPLEMENTATION_DATE}
                    title={NCRDataConstants.ACTUAL_IMPLEMENTATION_DATE_TITLE}
                    value={actualDate === "" ? this.state.targetImplementationDate : actualDate}
                    readonly={false}
                    required={false}
                 />
                <TextArea
                    dataName={NCRDataConstants.ACUTAL_IMPLEMENTATION_CHANGES}
                    title={NCRDataConstants.ACTUAL_IMPLEMENTATION_CHANGES_TITLE}
                    value={actualChanges === "" ? this.state.targetChanges : actualChanges}
                    readonly={false}
                    required={true}
                    rows={3}
                />
                <MultiSelectField
                    onChange={(data) => {this.onMultiSelectChange(data)}}
                    dataName={NCRDataConstants.CLOSURE_STATUS}
                    value={this.getScreenFieldValue(NCRDataConstants.CLOSURE_STATUS)}
                    options={multiSelectOptions}
                    title={NCRDataConstants.CLOSURE_STATUS_TITLE}
                    required={true}
                    specialOptions={specialOptions}
                    specialOptionsTitle="Select All"
                    error={this.state.isNcrClosureStatusError}
                    errorMessage="Cannot have 'No Change' selected with other values."
                />
            </div>
        )
    }

    render () {
        const { 
            closeNCRStep1Value
           } = this.state;

        return (
            <div>
                <Header title={NCRDataConstants.CORPORATE_ANALYSIS} headerColor="white" textColor="black" />
                <SelectField dataName={NCRDataConstants.CLOSE_NCR_STEP_1} title={NCRDataConstants.CLOSE_NCR_STEP_1_TITLE} options={[ { value: NCRDataConstants.WITH_ACTION_ITEMS }, { value: NCRDataConstants.WITHOUT_ACTION_ITEMS }]} value={closeNCRStep1Value} 
                    readonly={!this.checkIfQualityExpediting()} required={this.checkIfQualityExpediting()} onchange={this.handleCloseNCRStep1Change} />
                {this.qualityExpedtingFields()}
                {!this.checkIfQualityExpediting() ? this.corporateAnalysis() : "" }
                {!this.checkIfCorporateAnalysis() ? this.implementation() : ""}
            </div>
        )
    }
}
