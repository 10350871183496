import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { SaveStatus } from '../../enums/SaveStatus';

import '../../styles/Common.css';

interface SaveButtonProps {
    saveStatus: SaveStatus,
    onClick?: () => void | undefined,
};

const SaveButton = ({ saveStatus, onClick }: SaveButtonProps) => {
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        if (saveStatus !== SaveStatus.Saving) {
            setSaving(false);
        }
    }, [saveStatus]);

    const getButtonString = (status: SaveStatus) => {
        switch(status) {
            case SaveStatus.Unsaved:
                return 'Save';

            case SaveStatus.Saving:
                return 'Saving';

            case SaveStatus.Saved:
                return 'Saved';

            case SaveStatus.Error:
                return 'Failed';
        }
    };

    const getButtonColoring = (status: SaveStatus) => {
        switch(status) {
            case SaveStatus.Unsaved:
                return 'btn-primary';

            case SaveStatus.Saving:
                return 'btn-outline-primary';

            case SaveStatus.Saved:
                return 'btn-outline-success';

            case SaveStatus.Error:
                return 'btn-outline-danger';
        }
    };

    const handleOnClick = () => {
        if (saveStatus === SaveStatus.Saved) {
            return;
        }
        setSaving(true);
        if(onClick) {
            onClick();
        }
    };

    return (
        <button
            type='button'
            className={`btn data-source-btn ${getButtonColoring(saveStatus)}`}
            onClick={handleOnClick}
        >
        {saving && <Spinner animation='border' size='sm' role='status' aria-hidden='true' />}
        {!saving && (getButtonString(saveStatus))}
        </button>
    );
}

export default SaveButton;