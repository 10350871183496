import React from 'react';
import { Button, Col, Collapse } from 'react-bootstrap';

type CollapseFieldProps = {
    title: string,
    hintText?: string,
    buttonStyle?: string,
    hintTextColor?: string
}

type State = {
    open: boolean
}

export default class CollapseField extends React.Component<CollapseFieldProps, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            open: false
        }
    }

    /**
     * Sets the collapse field to opened or closed
     * @param isOpen Whether collapse is open or not
     */
    setOpen(isOpen: boolean) {
        this.setState({
            open: isOpen
        });
    }
    
    render() {
        const {
            open
        } = this.state;

        return (
            <div>
                <div className="d-grid gap-2">
                    <Button className={this.props.buttonStyle ? this.props.buttonStyle : ""} onClick={() => this.setOpen(!open)} variant="primary">
                        <Col style={{display:'flex', justifyContent:'left'}}>
                            <b>{open ? "▾ " : "▸ "}{this.props.title}</b>&nbsp;&nbsp;&nbsp;&nbsp;<div style={{color: this.props.hintTextColor ? this.props.hintTextColor : "#939598"}}><i>{this.props.hintText}</i></div>
                        </Col>
                    </Button>
                </div>
                <Collapse in={open}>
                    <div>
                        {this.props.children}
                    </div>
                </Collapse>
            </div>
        )
    }
}