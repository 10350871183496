import React, { ForwardedRef } from'react';
import { Button } from 'react-bootstrap';
import add from '../images/add_square.png'

type AddButtonProps = {
    dataName: string,
    title: string
    readOnly?: boolean,
    eventHandler?: (e: any) => void
}

const AddButton = React.forwardRef(({dataName, title, eventHandler, readOnly}: AddButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return(
        <div>
            <Button id="AddButton" name={dataName} onClick={eventHandler} disabled={readOnly}>
                <div className="d-flex justify-content-start align-items-center">
                    <img
                        alt="add"
                        src={add}
                        width="30px"
                        height="30px"
                    />
                    <b>&nbsp;{title}</b>
                </div>
            </Button>
        </div>
    );
});

export default AddButton;