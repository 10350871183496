import React from 'react';
import Header from './Header';

type SectionWrapperProps = {
    visible: boolean,
    children: React.ReactNode,
    headerTitle?: string,
    headerHintText?: string,
    headerColor?: string,
    headerTextColor?: string
} & typeof defaultProps;

const defaultProps = {
    visible: true
}

const SectionWrapper = ({visible, children, headerTitle, headerHintText, headerColor, headerTextColor}: SectionWrapperProps) => {
    const header = (
        headerTitle || headerHintText ? 
            <Header 
                title={headerTitle ? headerTitle : ""} 
                hintText={headerHintText} 
                headerColor={headerColor ? headerColor : "white"} 
                textColor={headerTextColor ? headerTextColor : "black"} 
            />
        : null
    );

    return (
        visible ? 
        <div>
            {header}
            {children}
        </div>
        : <></> // If not visible, return empty element instead of null
    );
}


SectionWrapper.defaultProps = defaultProps;

export default SectionWrapper;