import React from'react';
import { Spinner } from 'react-bootstrap';
import Delayed from './Delayed';

type LoadingProps = {
    showLoading: boolean
}

const Loading = ({ showLoading }: LoadingProps) => {

    return (
        showLoading ?
            <Delayed waitBeforeShow={250}>
                <div className = "DivCenter">
                    <br />
                    <Spinner animation="border" role="status" variant="primary" />
                    <br />
                    <p>Loading...</p>
                </div>
            </Delayed>
        : null
    );
}

export default Loading