const QuantaMasterJobCodeList = {
    BAM050: 'Administrative Assistant',
    BAM200: 'Senior Administrative Assistant',
    BAM201: 'Executive Assistant',
    BAM250: 'Senior Executive Assistant',
    BAM750: 'Executive Business Partner',
    BBS050: 'Environmental Services Assistant',
    BBS051: 'Building Services Support Assistant',
    BBS052: 'Receptionist',
    BBS100: 'Building Services Support Specialist',
    BBS200: 'Building Services Coordinator',
    BBS650: 'Manager I - Building Services',
    BCO001: 'Business Development Intern',
    BCO050: 'Business Development Assistant',
    BCO100: 'Client Relationship Specialist',
    BCO200: 'Senior Client Relationship Specialist',
    BCO300: 'Associate Business Development Manager - Client Accounts',
    BCO350: 'Business Development Manager - Client Accounts',
    BCO351: 'Salesforce Administrator',
    BCO352: 'Business Development Analyst',
    BCO353: 'Sales Development Manager',
    BCO400: 'Senior Business Development Manager - Client Accounts',
    BCO401: 'Senior Business Development Analyst',
    BCO450: 'Lead Business Development Strategist',
    BCO600: 'Manager I - Business Development - Operations Support',
    BCO601: 'Manager I - Business Development Analytics',
    BCO800: 'Director - Business Development - Account Management',
    BCO900: 'Vice President - Account Management',
    BCO901: 'Vice President - Sales',
    BCO960: 'Executive Vice President - Business Development',
    BEN001: 'CAD Designer Intern',
    BEN002: 'Engineering Designer Intern',
    BEN003: 'Engineering Intern',
    BEN100: 'CAD Designer',
    BEN200: 'CAD Designer II',
    BEN201: 'Engineering Coordinator',
    BEN250: 'Senior CAD Designer',
    BEN300: 'Engineer I - Electrical',
    BEN301: 'Engineer I - Civil & Structures',
    BEN302: 'Associate Geospatial Software Developer',
    BEN350: 'Performance Analyst',
    BEN351: 'Engineer II - Civil and Structures',
    BEN352: 'Engineer II - Electrical',
    BEN353: 'Geospatial Software Developer',
    BEN400: 'Senior GIS Analyst',
    BEN401: 'Senior Engineer Technician',
    BEN402: 'Senior Engineer - Civil & Structures',
    BEN403: 'Senior SCADA Engineer',
    BEN404: 'Senior Engineer - Crane and Rigging',
    BEN405: 'Senior Engineer - Electrical',
    BEN406: 'Senior Project Engineer',
    BEN407: 'Senior Engineer - Transmission',
    BEN408: 'Senior Engineer - Geotechnical',
    BEN409: 'Senior Geospatial Software Developer',
    BEN450: 'Lead Power Systems',
    BEN451: 'Lead Energy Storage Program',
    BEN452: 'Lead Engineer - Geotechnical',
    BEN453: 'Lead Engineer - Electrical',
    BEN454: 'Lead Engineer - Civil & Structures',
    BEN455: 'Lead Project Engineer',
    BEN500: 'Principal Engineer - Civil & Structures',
    BEN650: 'Manager I - Civil & Structures Engineering',
    BEN651: 'Manager I - Electrical Engineering',
    BEN652: 'Manager I - Project Engineering',
    BEN700: 'Manager II - Engineering',
    BEN750: 'Senior Manager -  Engineering',
    BEN800: 'Director - Engineering',
    BES001: 'Estimating Intern',
    BES100: 'Estimating Support Specialist',
    BES300: 'Associate Estimator',
    BES350: 'Estimator',
    BES400: 'Senior Estimator',
    BES401: 'Senior Special Projects Advisor',
    BES450: 'Lead Estimator',
    BES650: 'Manager I - Estimating',
    BES700: 'Manager II - Estimating',
    BES750: 'Senior Manager - Estimating',
    BES801: 'Director - Estimating',
    BEX998: 'President',
    BEX999: 'Chief Executive Officer',
    BFI001: 'Accounting Intern',
    BFI002: 'Finance Intern',
    BFI050: 'Lien Waivers Assistant',
    BFI051: 'Project Assistant - Finance',
    BFI052: 'Payroll Assistant',
    BFI100: 'Accounts Payable Associate',
    BFI101: 'Accounts Payable Associate - Projects',
    BFI102: 'Corporate Travel Specialist',
    BFI103: 'Payroll Associate',
    BFI200: 'Senior Accounts Payable Associate',
    BFI201: 'Senior Accounts Payable Associate - Projects',
    BFI202: 'Senior Corporate Travel Specialist',
    BFI203: 'Payroll Specialist',
    BFI250: 'Senior Payroll Specialist',
    BFI300: 'Associate Accountant',
    BFI301: 'Associate Financial Analyst',
    BFI302: 'Associate Project Manager - Payroll',
    BFI350: 'Accountant',
    BFI351: 'Tax Accountant',
    BFI352: 'Financial Analyst',
    BFI353: 'Payroll Analyst',
    BFI354: 'Payroll Tax Analyst',
    BFI400: 'Senior Tax Accountant',
    BFI401: 'Senior Accountant',
    BFI402: 'Senior Control Self-Assessment Advisor',
    BFI403: 'Senior Financial Analyst',
    BFI404: 'Senior Payroll Analyst',
    BFI405: 'Senior Payroll Systems Analyst',
    BFI450: 'Lead Accountant',
    BFI451: 'Lead Financial Analyst',
    BFI452: 'Payroll Project Manager',
    BFI600: 'Supervisor - Accounts Payable - Vendor Compliance & Payments',
    BFI601: 'Supervisor - Accounts Payable',
    BFI602: 'Supervisor - Travel',
    BFI603: 'Supervisor - Payroll',
    BFI650: 'Manager I - Internal Controls',
    BFI651: 'Manager I - Accounts Payable',
    BFI652: 'Manager I - Finance',
    BFI653: 'Manager I - Payroll',
    BFI700: 'Manager II - Internal Controls',
    BFI701: 'Manager II - Accounting',
    BFI702: 'Manager II - Payroll',
    BFI750: 'Senior Manager - Accounts Payable',
    BFI800: 'Director - Accounting & Controller',
    BFI801: 'Director - Finance',
    BFI802: 'Director - Payroll',
    BFI850: 'Senior Director - Finance',
    BFI900: 'Vice President',
    BFI901: 'Executive Vice President - Finance',
    BFI960: 'Executive Vice President - Chief Financial Officer',
    BFM100: 'Leadperson - Grading',
    BFM101: 'Leadperson - Installation',
    BFM102: 'Leadperson - Structures',
    BFM103: 'Leadperson - Lineman',
    BFM104: 'Leadperson - Electrical',
    BFM105: 'Leadperson - Wireman',
    BFM200: 'Foreman - Crane',
    BFM201: 'Foreman - Electrical',
    BFM202: 'Foreman - Grading',
    BFM203: 'Foreman - Laborer',
    BFM204: 'Foreman - Lineman',
    BFM205: 'Foreman - Maintenance - Nonworking',
    BFM206: 'Foreman - Maintenance- CDL',
    BFM207: 'Foreman - Structures (Concrete)',
    BFM208: 'Foreman - Commissioning',
    BFM209: 'Foreman - Installation',
    BFM210: 'Foreman - Wireman',
    BFM250: 'Commissioning Lead',
    BFM350: 'Field Engineer Electrical',
    BFM351: 'Field Construction Coordinator Electrical',
    BFM352: 'Field Engineer',
    BFM353: 'Field Construction Coordinator',
    BFM600: 'Quality Superintendent',
    BFM601: 'Quality Superintendent II',
    BFM602: 'Project Engineer',
    BFM603: 'Project Construction Coordinator',
    BFM650: 'Site Safety Manager',
    BFM651: 'Site Safety Manager II',
    BFM652: 'Assistant Electrical Superintendent',
    BFM653: 'Electrical Superintendent',
    BFM654: 'Assistant Grading Superintendent',
    BFM655: 'Grading Superintendent',
    BFM656: 'Manager I - Crane Operations',
    BFM657: 'Assistant Installation Superintendent',
    BFM658: 'Installation Superintendent',
    BFM659: 'Structures Superintendent II',
    BFM660: 'Grading Superintendent II',
    BFM661: 'Installation Superintendent II',
    BFM662: 'Electrical Superintendent II',
    BFM663: 'Assistant Structures Superintendent',
    BFM664: 'Structures Superintendent',
    BFM665: 'GPS Equipment Superintendent',
    BFM700: 'Site Manager',
    BFS001: 'Field Construction Coordinator Electrical Intern',
    BFS002: 'Field Construction Coordinator Intern',
    BFS003: 'Site Quality Coordinator Intern',
    BFS004: 'Site Safety Coordinator Intern',
    BFS100: 'Trainer',
    BFS101: 'Site Security Guard',
    BFS103: 'Inventory Asst',
    BFS104: 'Project Administration Assistant',
    BFS105: 'Project Staffing Specialist',
    BFS106: 'Site Security',
    BFS107: 'Site Inventory Specialist',
    BFS108: 'Senior Site Inventory Specialist',
    BFS110: 'Quality Control Inspector',
    BFS111: 'QA/QC Inspector',
    BFS200: 'Construction Craft Laborer - Apprentice',
    BFS201: 'Construction Craft Laborer - Journeyworker',
    BFS202: 'Construction Equipment Tech Apprentice',
    BFS203: 'Lube Tech - Apprentice',
    BFS204: 'Wind Turbine Installer - Apprentice',
    BFS205: 'Wind Turbine Installer - Journeyworker',
    BFS206: 'Cable Installer - Apprentice',
    BFS207: 'Cable Installer - Journeyworker',
    BFS208: 'Substation Technician - Apprentice',
    BFS209: 'Substation Technician - Journeyworker',
    BFS210: 'Electrician Laborer',
    BFS211: 'Electrician Laborer - CDL',
    BFS212: 'Electrician Apprentice',
    BFS213: 'Electrician - Journeyman',
    BFS214: 'Wireman',
    BFS215: 'Wireman Apprentice',
    BFS216: 'Wireman Journeyman',
    BFS217: 'Mechanical Completion/Technician',
    BFS218: 'Mechanic- Field - CDL',
    BFS219: 'Mechanic',
    BFS220: 'Oiler - Non CDL',
    BFS221: 'Oiler - CDL',
    BFS222: 'Grading Laborer',
    BFS223: 'Grading Laborer - CDL',
    BFS224: 'Carpenter - Apprentice',
    BFS225: 'Carpenter - Journeyworker',
    BFS226: 'Form Carpenter',
    BFS227: 'Ironworker - Apprentice',
    BFS228: 'Ironworker - Journeyworker',
    BFS229: 'Turbine Installation (Non-Climber)',
    BFS230: 'Turbine Installation (Climber)',
    BFS231: 'Turbine Install (Non Climber) - CDL',
    BFS232: 'Turbine Install - Climber - CDL',
    BFS234: 'Structures Laborer (Concrete)',
    BFS235: 'Structures Laborer- CDL',
    BFS236: 'Lineman Apprentice',
    BFS237: 'Lineman Journeyman',
    BFS238: 'Lineman Laborer',
    BFS239: 'T-line Aerial/Bucket Truck',
    BFS240: 'T-Line Elevated Worker',
    BFS241: 'Solar Installation',
    BFS242: 'Flagger',
    BFS243: 'Concrete Finisher',
    BFS244: 'Groundworker',
    BFS246: 'Tool Technician',
    BFS247: 'General Laborer - Apprentice',
    BFS248: 'General Laborer - Journeyworker',
    BFS249: 'Field Surveyor',
    BFS500: 'Articulated Truck - Off Highway',
    BFS501: 'Dump Truck (On Highway) - CDL',
    BFS502: 'Haul Truck (Off Highway)',
    BFS503: 'Water Truck - CDL',
    BFS504: 'Tractor',
    BFS505: 'Heavy Equipment Operator - Apprentice',
    BFS506: 'Heavy Equipment Operator - Journeyworker',
    BFS507: 'Dozer',
    BFS508: 'Finish Dozer',
    BFS509: 'Motor Grader',
    BFS510: 'Finish Motor Grader',
    BFS511: 'Boom Truck',
    BFS512: 'Crane',
    BFS513: 'Rough Terrain Crane',
    BFS514: 'Lattice Crane Operator',
    BFS515: 'Rough Terrain & Crawler Crane',
    BFS516: 'Hydraulic 300 Ton Plus Crane',
    BFS517: 'Scraper',
    BFS518: 'Pile Driver',
    BFS519: 'Excavator (Backhoe/Shovel/Trackhoe)',
    BFS520: 'Forklift/Telehandler',
    BFS521: 'Loader',
    BFS522: 'Roller/Compacter',
    BFS523: 'Grade Setter/Grade Checker',
    BFS524: 'Driller',
    BFS525: 'Lineman Operator',
    BFS526: 'Wireman Operator',
    BHR001: 'HR Intern',
    BHR002: 'Talent Acquisition Coordinator Intern',
    BHR100: 'Total Rewards Specialist',
    BHR101: 'Benefits Specialist',
    BHR102: 'Compensation Specialist',
    BHR200: 'HR Operations Coordinator',
    BHR201: 'HR Service Center Coordinator',
    BHR202: 'Onsite Trainer',
    BHR203: 'Talent Development Coordinator',
    BHR204: 'Talent Acquisition Coordinator',
    BHR300: 'Associate HR Business Partner',
    BHR301: 'Associate Talent Development Partner',
    BHR302: 'Associate Instructional Designer',
    BHR303: 'Business Data Analyst I - Human Resources',
    BHR304: 'Workforce Planning Analyst I',
    BHR305: 'Associate Sourcer',
    BHR306: 'Associate Recruiter',
    BHR307: 'Associate Compensation Analyst',
    BHR350: 'HR Business Partner',
    BHR351: 'HR Services Analyst',
    BHR352: 'HR Operations Analyst',
    BHR353: 'HR Technology Analyst',
    BHR354: 'Project Manager - HR Technology',
    BHR355: 'Project Manager - Human Resources',
    BHR356: 'HRIS Analyst',
    BHR357: 'Learning Experience Designer',
    BHR358: 'Talent Development Partner',
    BHR359: 'Field Training Program Administrator',
    BHR360: 'LMS Analyst',
    BHR361: 'Instructional Designer',
    BHR362: 'Workforce Development Partner',
    BHR363: 'Business Data Analyst II - Human Resources',
    BHR364: 'Workforce Planning Analyst II',
    BHR365: 'Data Scientist',
    BHR366: 'Sourcer',
    BHR367: 'Recruiter',
    BHR368: 'Absence Management Advisor',
    BHR400: 'Senior HR Business Partner',
    BHR401: 'Senior HRIS Analyst',
    BHR402: 'Senior Instructional Designer',
    BHR403: 'Senior Workforce Planning Analyst',
    BHR404: 'Talent Acquisition Project Partner',
    BHR405: 'Senior Recruiter',
    BHR406: 'Senior Compensation Analyst',
    BHR407: 'Senior Benefits Analyst - Health & Wellness',
    BHR408: 'Senior Benefits Analyst - Leave Management & Retirement',
    BHR451: 'HR Compliance Manager',
    BHR452: 'HR Project Manager',
    BHR453: 'Lead Data Scientist',
    BHR600: 'Supervisor - HR Operations',
    BHR601: 'Supervisor - Talent Acquisition',
    BHR650: 'Manager I - HR Business Partner',
    BHR651: 'Manager I - HR Operations',
    BHR652: 'Manager I - HR Services',
    BHR653: 'Manager I - HR Technology',
    BHR654: 'Manager I - Talent Development',
    BHR655: 'Manager I - Talent Acquisition - Corporate and Collaborative Partnerships',
    BHR656: 'Manager I - Compensation',
    BHR700: 'Manager II - Field Learning & Development',
    BHR701: 'Manager II - Talent Development',
    BHR702: 'Manager II - Talent Acquisition - Construction',
    BHR751: 'Senior Manager - HR Business Partner',
    BHR752: 'Senior Manager - HR Services',
    BHR753: 'Senior Manager - HRIS',
    BHR754: 'Senior Manager - HR Data and Resource Planning Analytics',
    BHR755: 'Senior Manager - Talent Acquisition',
    BHR756: 'Senior Manager - Total Rewards',
    BHR800: 'Director - Talent Management',
    BHR801: 'Director - HR Business Partner',
    BHR802: 'Director - HR Services',
    BHR803: 'Director - HR Solutions',
    BHR804: 'Director - Total Rewards',
    BHR901: 'Vice President',
    BHR960: 'Chief Human Resource Officer',
    BHS100: 'Safety Improvement Specialist',
    BHS101: 'Safety Program Specialist',
    BHS102: 'Claims Specialist - Workers Compensation',
    BHS350: 'Business Data Analyst',
    BHS351: 'Claims Analyst - Workers Compensation',
    BHS650: 'Manager I - Claims',
    BHS651: 'Manager I - HSE Data Analytics',
    BHS652: 'Manager I - HSE Programs',
    BHS700: 'Manager II - Workers Compensation',
    BHS750: 'Regional HSE Manager',
    BHS751: 'Senior Manager - Health',
    BHS900: 'Vice President - HSE',
    BIT001: 'IT Intern',
    BIT050: 'Help Desk I',
    BIT100: 'Help Desk II',
    BIT101: 'IT Field Services Technician',
    BIT102: 'Endpoint Support Specialist',
    BIT200: 'Network Administrator',
    BIT250: 'Lead IT Field Services Technician',
    BIT300: 'Associate Business System Analyst',
    BIT301: 'Associate Endpoint Systems Engineer',
    BIT302: 'Associate Master Data Analyst',
    BIT303: 'Associate Database Developer',
    BIT304: 'Associate Identity & Access Management Analyst',
    BIT305: 'Associate Cloud Engineer',
    BIT306: 'Associate Application Developer',
    BIT307: 'Associate Reliability Engineer',
    BIT350: 'Business System Analyst',
    BIT351: 'IT Release Process Manager',
    BIT352: 'Collaboration Engineer',
    BIT353: 'Master Data Analyst',
    BIT354: 'Application Developer',
    BIT355: 'Logging & Alerting Engineer',
    BIT356: 'Cloud Engineer',
    BIT357: 'Reliability Engineer',
    BIT400: 'Senior Business System Analyst',
    BIT401: 'Senior Database Developer',
    BIT402: 'Solutions Architect',
    BIT403: 'Senior Master Data Analyst',
    BIT404: 'GIS Solutions Architect',
    BIT405: 'Senior Identity & Access Management Analyst',
    BIT406: 'Senior Application Developer',
    BIT450: 'Lead IT Project Manager',
    BIT451: 'Senior Solutions Architect',
    BIT452: 'Lead Application Developer',
    BIT500: 'IT Business Partner',
    BIT600: 'Supervisor - Endpoint Strategy Team',
    BIT601: 'Supervisor - Help Desk',
    BIT650: 'Manager I - Business System Analyst',
    BIT651: 'Manager I - Help Desk',
    BIT652: 'Manager I - Data Analytics & Integration',
    BIT653: 'Manager I - Master Data Management',
    BIT654: 'Manager I -  Reliability Engineer',
    BIT700: 'Manager II - IT Field Services',
    BIT701: 'Manager II - Help Desk',
    BIT702: 'Manager II - Data Analytics & Integration',
    BIT750: 'Senior Manager - Portfolio',
    BIT800: 'Director - Information Technology',
    BIT801: 'Director - IT Enterprise Architect',
    BIT960: 'Executive Vice President - Chief Information Officer',
    BLE001: 'Law Clerk',
    BLE050: 'Administrative Assistant - Legal',
    BLE051: 'Compliance Assistant',
    BLE100: 'Claims Specialist - Property',
    BLE101: 'Contract Support Specialist',
    BLE102: 'Labor Relations Specialist',
    BLE103: 'Legal Operations Specialist',
    BLE200: 'Paralegal',
    BLE201: 'Senior Compliance Assistant',
    BLE202: 'Corporate Contract Specialist',
    BLE203: 'Project Support Coordinator',
    BLE204: 'IRA Program Support Coordinator',
    BLE250: 'Senior Paralegal',
    BLE251: 'Senior Project Support Coordinator',
    BLE252: 'Senior Corporate Contract Specialist',
    BLE253: 'Lead Corporate Contract Specialist',
    BLE300: 'Legal Operations Analyst',
    BLE350: 'Associate Counsel',
    BLE351: 'Licensing Analyst',
    BLE352: 'Data Governance Analyst',
    BLE353: 'Claims Analyst - Property',
    BLE354: 'Enterprise Risk Management Analyst',
    BLE355: 'Contracts Administrator I',
    BLE356: 'Labor Relations Advisor',
    BLE400: 'Senior Compliance Analyst',
    BLE401: 'Contracts Administrator II',
    BLE450: 'Enterprise Risk Manager',
    BLE451: 'IRA Program Manager',
    BLE452: 'Senior Labor Relations Advisor',
    BLE453: 'Lead Legal Operations Analyst',
    BLE500: 'Legal Counsel',
    BLE501: 'Labor Relations Counsel',
    BLE550: 'Senior  Counsel',
    BLE600: 'Supervisor - Legal Project Support',
    BLE650: 'Manager I - Compliance',
    BLE651: 'Manager I - Contract Administration',
    BLE652: 'Manager I - Contract Administration & Project Support',
    BLE700: 'Manager II - Legal Operations',
    BLE750: 'Senior Manager - Data Governance',
    BLE751: 'Senior Manager - Insurance & Risk',
    BLE800: 'Director - Compliance & Risk Management',
    BLE801: 'Director - Contracts Management',
    BLE850: 'Associate General Counsel',
    BLE960: 'Executive Vice President - General Counsel',
    BMK001: 'Multimedia Design Intern',
    BMK100: 'Marketing & Communications Specialist',
    BMK101: 'Corporate Communications Specialist',
    BMK300: 'Associate Project Manager - Corporate Communications',
    BMK301: 'Associate Corporate Communications Brand Strategist',
    BMK302: 'Associate Communications Editor',
    BMK350: 'Graphic Designer',
    BMK351: 'Communications Business Partner',
    BMK400: 'Senior Graphic Designer',
    BMK401: 'Senior Multimedia Designer',
    BMK402: 'Senior Community Relations Partner',
    BMK403: 'Senior Internal Communications Business Partner',
    BMK404: 'Senior Communications Business Partner',
    BMK450: 'External Communications Manager',
    BMK650: 'Manager I - Corporate Communications - Operations & Resources',
    BMK750: 'Senior Manager - Creative Services',
    BMK751: 'Senior Manager - External Communications',
    BMK752: 'Senior Manager - Communications',
    BMK800: 'Director - Marketing & Communications',
    BOP100: 'Construction Support Specialist',
    BOP200: 'Construction Support Coordinator',
    BOP202: 'Operations Strategy & Execution Coordinator',
    BOP300: 'Associate Portfolio Scheduler',
    BOP301: 'Associate Project Scheduler',
    BOP350: 'SCADA Commissioning Technician',
    BOP351: 'Portfolio Scheduler',
    BOP352: 'Project Scheduler',
    BOP400: 'Senior Estimator - Innovation & Development',
    BOP401: 'Senior Portfolio Scheduler',
    BOP402: 'Senior Project Scheduler',
    BOP450: 'Lead Operations Technical Instructor',
    BOP550: 'Construction Support Leader',
    BOP650: 'Manager I - Warranty',
    BOP651: 'Commissioning Superintendent',
    BOP652: 'Manager I - Portfolio Controls',
    BOP653: 'Manager I - Project Controls',
    BOP700: 'Manager II - GIS Programs',
    BOP701: 'Manager II - Project Controls',
    BOP750: 'General Superintendent',
    BOP751: 'Project Manager',
    BOP752: 'Project Manager II',
    BOP753: 'Senior Project Manager',
    BOP754: 'Senior Manager - Innovation & Development',
    BOP800: 'Director',
    BOP801: 'Director',
    BOP802: 'Director - Commissioning',
    BOP803: 'Director - Field Operations',
    BOP804: 'Director - Operations Strategy & Execution',
    BOP805: 'Director - Portfolio & Project Controls',
    BOP850: 'Senior Director - Construction - Solar',
    BOP851: 'Senior Director - Construction - Wind',
    BOP852: 'Senior Director - Construction - Storage',
    BOP853: 'Senior Director - Operations Strategy & Execution',
    BOP900: 'Vice President - Engineering',
    BOP901: 'Vice President - Wind',
    BOP902: 'Vice President - Solar',
    BOP903: 'Vice President - Solar & Storage',
    BOP930: 'Senior Vice President - General Manager - Wind',
    BOP931: 'Senior Vice President - General Manager - Solar',
    BOP960: 'Executive Vice President - Chief Operating Officer',
    BQU001: 'Quality Department Intern',
    BQU100: 'Quality Specialist',
    BQU350: 'Quality Data Analyst',
    BQU351: 'Quality Systems Analyst',
    BQU400: 'Senior Quality Systems Analyst',
    BQU450: 'Quality Control Manager',
    BQU650: 'Manager I - Quality',
    BQU800: 'Director - Quality',
    BSC001: 'Equipment Category Intern',
    BSC002: 'Supply Chain Sourcing & Purchasing Intern',
    BSC050: 'Part Time Driver',
    BSC051: 'Supply Chain Assistant',
    BSC052: 'Parts Runner & Shop Assistant',
    BSC053: 'Heavy Equipment & Fleet Technician I - Shop',
    BSC100: 'Fleet Safety Specialist - Compliance',
    BSC101: 'Fleet Safety Specialist - DOT',
    BSC102: 'Administrative Assistant II - Supply Chain',
    BSC103: 'Equipment Category Specialist',
    BSC104: 'Warehouse Materials Specialist',
    BSC105: 'Yard Operator',
    BSC106: 'Heavy Equipment & Fleet Technician II - Shop',
    BSC200: 'Senior Heavy Equipment & Fleet Technician - Shop',
    BSC201: 'Senior Warehouse Materials Specialist',
    BSC202: 'Senior Yard Operator',
    BSC250: 'Senior Regional Equipment Technician',
    BSC251: 'Senior Crane Technician',
    BSC252: 'Senior Pile Driver Technician',
    BSC300: 'Buyer I - Fleet',
    BSC301: 'Buyer I',
    BSC350: 'Fleet Safety Analyst',
    BSC351: 'Equipment System Administrator',
    BSC352: 'Equipment Technology Generalist',
    BSC353: 'Buyer II - Fleet',
    BSC354: 'Buyer II',
    BSC355: 'Supply Chain Data Analyst II',
    BSC356: 'Supplier Quality Engineer',
    BSC400: 'Senior Buyer - Fleet',
    BSC401: 'Senior Equipment Analyst',
    BSC402: 'Senior Buyer',
    BSC403: 'Senior Category Analytics & Insights Analyst',
    BSC450: 'Lead Category Sourcing',
    BSC451: 'Lead',
    BSC600: 'Supervisor - Equipment Accounts',
    BSC601: 'Warehouse Supervisor',
    BSC602: 'Supervisor - Shop',
    BSC650: 'Manager I - Maintenance Support',
    BSC651: 'Manager I - Regional Equipment',
    BSC652: 'Manager I - Equipment Lifecycle & Analytics',
    BSC653: 'Manager I - Transportation',
    BSC654: 'Manager I - Equipment Sourcing & Supply Management',
    BSC655: 'Manager I - Yard & Facility',
    BSC656: 'Manager I - Regional Inventory',
    BSC657: 'Manager I - Purchasing',
    BSC700: 'Manager II - Construction Equipment Maintenance & Repair',
    BSC701: 'Manager II - Crane Operations & Maintenance',
    BSC702: 'Manager II - Fleet Management',
    BSC703: 'Manager II - Heavy Equipment Management',
    BSC704: 'Manager II - Supplier Quality Engineer',
    BSC750: 'Senior Manager - Construction Equipment Maintenance & Repair',
    BSC751: 'Senior Manager - Crane & Rigging',
    BSC752: 'Senior Manager - Inventory & Warehousing',
    BSC753: 'Senior Manager - Category Sourcing and Purchasing',
    BSC754: 'Senior Manager - Subcontract Management Program',
    BSC800: 'Director - Equipment Sourcing & Supply Management',
    BSC801: 'Director - Construction Equipment',
    BSC802: 'Director - Sourcing & Supply Management',
    BSC930: 'Senior Vice President - Integrated Supply Chain',
    BTO100: 'Field Process Improvement Specialist',
    BTO200: 'Transformation Office Coordinator',
    BTO201: 'Document Services Coordinator',
    BTO300: 'Associate Enterprise Project Manager',
    BTO301: 'Associate Field Process Improvement Analyst',
    BTO350: 'Business Process Improvement Analyst',
    BTO351: 'Document Services Analyst',
    BTO352: 'Enterprise Project Manager',
    BTO353: 'Field Process Improvement Analyst',
    BTO400: 'Senior Field Improvement Analyst',
    BTO401: 'Senior Enterprise Project Manager',
    BTO402: 'Senior Field Process Improvement Analyst',
    BTO450: 'Lead Enterprise Project Manager',
    BTO650: 'Manager I - Business Process Improvement',
    BTO651: 'Manager I - Document Services',
    BTO652: 'Manager I - Field Process Improvement',
    BTO653: 'Manager I - Field Improvement',
    BTO700: 'Manager II - Business Process Improvement',
    BTO701: 'Manager II - Enterprise Project Management',
    BTO702: 'Manager II - Field Process Improvement',
    BTO960: 'Chief Transformation Officer',
}

export {
    QuantaMasterJobCodeList,
}