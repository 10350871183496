import BenchmarkDetail from "../types/ProjectGoal/BenchmarkDetail";
import http from "../http-common";
import ProjectGoalDetail from "../types/ProjectGoal/ProjectGoalDetail";

class ProjectGoalService {
    getBenchmarkDetails(projectID: string) {
        return http.get<BenchmarkDetail>(`/getBenchmarkDetails?projectID=${projectID}`);
    }

    getProjectGoalDetails(projectID: string, userEmail: string) {
        return http.get<ProjectGoalDetail>(`/getProjectGoalDetails?projectID=${projectID}&userEmail=${userEmail}`);
    }

    updateProjectGoalDetails(data: ProjectGoalDetail) {
        return http.post(`/updateProjectGoalDetails`, data, { headers: { 'Content-Type': 'application/json' }});
    }
}

const projectGoalServiceInstance = new ProjectGoalService();

export default projectGoalServiceInstance;