import { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";
import {Container, Modal, Nav, Navbar, NavDropdown,} from "react-bootstrap";

import Gear from './Icons/Gear';
import LogOut from './Icons/LogOut';
import NewTab from './Icons/NewTab';
import UserDataDisplay from "./UserDataDisplay";
import { hasRuleEditorAccess, hasAnyAdminAccess } from '../functions/AdminPageAccess';
import logo from '../images/logo.png'
import DataSourceEmployeeInfo from "../types/Employee/DataSourceEmployeeInfo";

type NavbarTypes = {
    userData: DataSourceEmployeeInfo
};

const infoItem = () => (
    <div className='row'>
        <div className='col-2'>
            <Gear size='14' />
        </div>
        <div className='col text-blattner-blue'>
            Info
        </div>
    </div>
);

const logOutItem = () => (
    <div className='row'>
        <div className='col-2'>
            <LogOut />
        </div>
        <div className='col text-blattner-blue'>
            Log Out
        </div>
    </div>
);

const NavbarTop = ({ userData }: NavbarTypes) => {
    const { accounts, instance } = useMsal();
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

    return (
        <Navbar style={{backgroundColor: "#02568a"}} collapseOnSelect fixed="top" expand="sm" variant="dark">
            <Container>
            <Modal
                className='modal-lg'
                show={showInfoModal}
                onHide={() => setShowInfoModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className='user-data-modal-header'>
                        <h3 className='ps-4 mt-2'>
                            {userData.firstName}&nbsp;{userData.lastName}&nbsp;
                            <a
                                href='/userinfo'
                                target='_blank'
                                onClick={() => setShowInfoModal(false)}
                                title='open user info page in new tab'
                            >
                                <NewTab />
                            </a>
                        </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                      className='mb-4'
                    >
                        <UserDataDisplay userData={userData} asModal={true}/>
                    </div>
                </Modal.Body>
            </Modal>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Navbar.Brand as={Link} to="/">
                        <img
                            src={logo}
                            width="200"
                            height="75"
                            alt=""
                        />
                    </Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/" style={{color: "white"}}>Home</Nav.Link>
                        <Nav.Link as={Link} to="/tasks" style={{color: "white"}} href="#Forms">NCR Tasks</Nav.Link>
                        <NavDropdown title="Roles" id="nav-dropdown">
                            <NavDropdown.Item as={Link} to="/dotcoordinators">DOT Coordinators</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/qualityformapprovers">Quality Form Approvers</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/projectgoals" style={{color: "white"}}>Project Goals</Nav.Link>
                        <Nav.Link as={Link} to="/dataEditor" style={{color: "white"}} href="#Forms">Data Editor</Nav.Link>
                        {
                            (hasRuleEditorAccess(userData))
                            && (<Nav.Link as={Link} to="/ruleEditor" style={{color: "white"}} href="#Forms">Rule Editor</Nav.Link>)
                        }
                        {
                            (hasAnyAdminAccess(userData))
                            && (<Nav.Link as={Link} to="/admin" style={{color: "white"}} href="#Forms">Admin</Nav.Link>)
                        }
                    </Nav>
                    <div className="WelcomeDiv" >
                        <h5>
                            <NavDropdown title={`Welcome ${userData.lastName}, ${userData.firstName}`} id="nav-dropdown">
                                <NavDropdown.Item onClick={() => setShowInfoModal(true)}>{infoItem()}</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => instance.logout()}>{logOutItem()}</NavDropdown.Item>
                            </NavDropdown>
                        </h5>
                    </div>
                </Navbar.Collapse>
            </Container>
      </Navbar>
    );
}

export default NavbarTop